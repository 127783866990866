import {useEffect, useState} from 'react';

import {isDefined, meshes as meshesLibrary} from '@/library';
import {useGlobalState} from '@/state';

export default function useSetMeshesVisibility() {
	const {
		meshes: globalMeshes,
		user: {user},
	} = useGlobalState();
	const [applied, setApplied] = useState<boolean>(false);

	function setMeshVisibility({
		id,
		visibility,
	}: {
		id: string;
		visibility: boolean;
	}) {
		meshesLibrary.setVisibility({id, visibility});
		globalMeshes.updateMesh({id, visibility});
	}

	useEffect(() => {
		if (applied || !globalMeshes.loaded || !isDefined(user)) {
			return;
		}

		const visibility = user.customDefaults?.visibility?.meshes;

		if (isDefined(visibility)) {
			for (const {id} of globalMeshes.meshes) {
				setMeshVisibility({id, visibility});
			}
		}

		setApplied(true);
	}, [applied, globalMeshes.loaded, user]);
}
