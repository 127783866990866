import vtkCylinderSource from '@kitware/vtk.js/Filters/Sources/CylinderSource';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import vtkCubeSource from '@kitware/vtk.js/Filters/Sources/CubeSource';
import vtkMath from '@kitware/vtk.js/Common/Core/Math';
import {vec3} from 'gl-matrix';

import {
	type DigitalTwinData,
	type ScreenPosition,
	type Vector3D,
} from '@/library/digital-twins';
import {type AxisValue, type GumballData} from '@/library/gumball';
import {useInteractionStore} from '@/state/interaction';
import {getViewports} from '@/state/viewports';

import {resizeGumball} from '../gumball';
import {
	getAxisVector,
	getScreenPositionRay,
	rayPlaneIntersection,
	setPosition,
} from '../math';

// eslint-disable-next-line complexity
export function handleResize(
	currentPosition: ScreenPosition,
	lastPosition: ScreenPosition,
	pickedAxis: AxisValue,
	selectedActorData: DigitalTwinData,
	gumball: GumballData,
) {
	const {
		volume: {renderWindow, renderer, camera},
	} = getViewports();
	const {isRKeyPressedAtStart} = useInteractionStore.getState();

	if (!camera) {
		throw new Error('Camera not found');
	}

	if (!renderer) {
		throw new Error('Renderer not found');
	}

	if (!renderWindow) {
		throw new Error('Render window not found');
	}

	// Creating a plane to move the object
	const actorCenter: Vector3D = selectedActorData.position;
	const pickedAxisVector: Vector3D = getAxisVector(
		pickedAxis,
		selectedActorData,
	);
	let planeNormal: Vector3D;
	switch (pickedAxis) {
		case 'x': {
			planeNormal = getAxisVector('z', selectedActorData);
			break;
		}

		case 'y': {
			planeNormal = getAxisVector('x', selectedActorData);
			break;
		}

		case 'z': {
			planeNormal = getAxisVector('y', selectedActorData);
			break;
		}

		default: {
			throw new Error('Invalid axis');
		}
	}

	const currentRay: Vector3D = getScreenPositionRay(
		currentPosition.x,
		currentPosition.y,
		renderer,
	);
	const current: Vector3D = rayPlaneIntersection(
		camera.getPosition(),
		currentRay,
		actorCenter,
		planeNormal,
	);
	const initialRay: Vector3D = getScreenPositionRay(
		lastPosition.x,
		lastPosition.y,
		renderer,
	);
	const initial: Vector3D = rayPlaneIntersection(
		camera.getPosition(),
		initialRay,
		actorCenter,
		planeNormal,
	);

	// Now, we need to project the vector between the current picked position and last picked position onto our desired axis.
	// With that, we can determine a delta and choose how far the object moves
	const deltaVector: Vector3D = {
		x: current.x - initial.x,
		y: current.y - initial.y,
		z: current.z - initial.z,
	};
	let negativeDirection: boolean = true;
	let scalarDelta =
		vtkMath.dot(
			[deltaVector.x, deltaVector.y, deltaVector.z],
			[pickedAxisVector.x, pickedAxisVector.y, pickedAxisVector.z],
		) /
		vtkMath.dot(
			[pickedAxisVector.x, pickedAxisVector.y, pickedAxisVector.z],
			[pickedAxisVector.x, pickedAxisVector.y, pickedAxisVector.z],
		);
	if (
		vtkMath.dot(
			vec3.subtract(
				vec3.create(),
				[initial.x, initial.y, initial.z],
				[actorCenter.x, actorCenter.y, actorCenter.z],
			),
			[pickedAxisVector.x, pickedAxisVector.y, pickedAxisVector.z],
		) < 0
	) {
		negativeDirection = false;
		scalarDelta *= -1;
	}

	const {dimensions, type} = selectedActorData;
	const newDimensions: Vector3D = {...dimensions};
	const oldDimensions: Vector3D = {...dimensions};
	newDimensions[pickedAxis] = oldDimensions[pickedAxis] + scalarDelta;

	const minimumSize = 0.001;
	// Ensure the dimension doesn't become negative
	newDimensions[pickedAxis] = Math.max(newDimensions[pickedAxis], minimumSize);
	const isShrinking: boolean = scalarDelta < 0;
	// Update the cube source
	// TODO: create a separate function that updates based on the specific source instead of the cube source
	// Check if the source exists
	if (selectedActorData.source) {
		switch (type) {
			case 'drill': {
				const source = selectedActorData.source as vtkCylinderSource;

				const radius = isShrinking
					? Math.min(newDimensions.x / 2, newDimensions.y / 2)
					: Math.max(newDimensions.x / 2, newDimensions.y / 2);

				source.setHeight(newDimensions.z);
				source.setRadius(radius);

				newDimensions.x = radius;
				newDimensions.y = radius;

				break;
			}

			case 'ream': {
				const source = selectedActorData.source as vtkSphereSource;

				const radius = isShrinking
					? Math.min(
							newDimensions.x / 2,
							newDimensions.y / 2,
							newDimensions.z / 2,
					  )
					: Math.max(
							newDimensions.x / 2,
							newDimensions.y / 2,
							newDimensions.z / 2,
					  );

				newDimensions.x = radius;
				newDimensions.y = radius;
				newDimensions.z = radius;
				source.setRadius(radius);

				break;
			}

			case 'resect': {
				const source = selectedActorData.source as vtkCubeSource;
				if (source) {
					source.setXLength(newDimensions.x);
					source.setYLength(newDimensions.y);
					source.setZLength(newDimensions.z);
				}

				break;
			}

			default: {
				throw new Error('Invalid type');
			}
		}

		if (isRKeyPressedAtStart) {
			if (!(scalarDelta < 0 && newDimensions[pickedAxis] === minimumSize)) {
				const oldCenter = selectedActorData.position;
				const newCenter = {
					x: negativeDirection
						? oldCenter.x + pickedAxisVector.x * 0.5 * scalarDelta
						: oldCenter.x - pickedAxisVector.x * 0.5 * scalarDelta,
					y: negativeDirection
						? oldCenter.y + pickedAxisVector.y * 0.5 * scalarDelta
						: oldCenter.y - pickedAxisVector.y * 0.5 * scalarDelta,
					z: negativeDirection
						? oldCenter.z + pickedAxisVector.z * 0.5 * scalarDelta
						: oldCenter.z - pickedAxisVector.z * 0.5 * scalarDelta,
				};
				setPosition(selectedActorData.actor, newCenter);
			}

			selectedActorData.source.modified();
		}
	}

	// Update the actor's bounds
	selectedActorData.actor.modified();

	// Update the gumball
	resizeGumball(gumball, {
		...selectedActorData,
		dimensions: newDimensions,
	});

	renderWindow.render();
}
