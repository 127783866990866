import {useEffect} from 'react';

import {isDefined, setSelectedResectionPlane} from '@/library';
import {useGlobalState} from '@/state';

export default function useSetPlane() {
	const {
		customDefaults,
		resectionPlanes,
		user: {user},
	} = useGlobalState();

	useEffect(() => {
		async function apply() {
			if (
				customDefaults.resectionPlanes.plane.applied ||
				// Wait until pair has been applied
				!customDefaults.resectionPlanes.pair.applied ||
				!isDefined(user) ||
				resectionPlanes.state !== 'ready'
			) {
				return;
			}

			const plane = user.customDefaults?.resectionPlanes?.plane;

			if (isDefined(plane) && plane !== resectionPlanes.selectedPlaneKey) {
				await setSelectedResectionPlane({plane});
			}

			customDefaults.resectionPlanes.plane.setApplied(true);
		}

		apply().catch(console.error);
	}, [
		customDefaults.resectionPlanes.pair.applied,
		customDefaults.resectionPlanes.plane.applied,
		resectionPlanes.state,
		user,
	]);
}
