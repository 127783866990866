import {useEffect, useState} from 'react';

import {cornerstone, isDefined} from '@/library';
import {useGlobalState} from '@/state';

export default function useSetSegmentationOverlaysVisibility() {
	const {
		user: {user},
		viewports: {areCornerstoneViewportsInitialized: areViewportsInitialized},
		visibility: globalVisibility,
	} = useGlobalState();
	const [applied, setApplied] = useState<boolean>(false);

	useEffect(() => {
		if (applied || !areViewportsInitialized || !isDefined(user)) return;

		const visibility = user.customDefaults?.visibility?.segmentationOverlays;

		if (
			isDefined(visibility) &&
			visibility !== globalVisibility.segmentationOverlays
		) {
			cornerstone.segmentationOverlays.setVisibility(visibility);
			globalVisibility.setVisibility('segmentationOverlays', visibility);
		}

		setApplied(true);
	}, [applied, areViewportsInitialized, user]);
}
