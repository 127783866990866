import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkCircleSource from '@kitware/vtk.js/Filters/Sources/CircleSource';
import vtkCubeSource from '@kitware/vtk.js/Filters/Sources/CubeSource';
import vtkLineSource from '@kitware/vtk.js/Filters/Sources/LineSource';
import vtkMatrixBuilder from '@kitware/vtk.js/Common/Core/MatrixBuilder';
import {mat4} from 'gl-matrix';

import {type DigitalTwinData, type Vector3D} from '@/library/digital-twins';
import {AXES, type ActorAndSource, type GumballData} from '@/library/gumball';
import {useInteractionStore} from '@/state/interaction';
import {getViewports} from '@/state/viewports';

import {getPosition, setPosition, setTransformationMatrix} from './math';
import {makeCircle, makeCube, makeCylinder, makeGumball} from './geometry';

function adjustCameraForGumball(actorData: DigitalTwinData) {
	const {
		volume: {camera, renderer},
	} = getViewports();
	if (!camera) {
		throw new Error('Camera not found');
	}

	if (!renderer) {
		throw new Error('Renderer not found');
	}

	const bounds = actorData.actor.getBounds();

	const diagonalLength = Math.hypot(
		bounds[1] - bounds[0],
		bounds[3] - bounds[2],
		bounds[5] - bounds[4],
	);

	camera.setClippingRange(diagonalLength * 0.01, diagonalLength * 100);

	const cameraPosition = camera.getPosition();
	const focalPoint = camera.getFocalPoint();
	const direction = [
		focalPoint[0] - cameraPosition[0],
		focalPoint[1] - cameraPosition[1],
		focalPoint[2] - cameraPosition[2],
	];
	const distance = Math.hypot(direction[0], direction[1], direction[2]);

	if (distance < diagonalLength) {
		const newPosition = [
			focalPoint[0] - (direction[0] / distance) * diagonalLength * 1.5,
			focalPoint[1] - (direction[1] / distance) * diagonalLength * 1.5,
			focalPoint[2] - (direction[2] / distance) * diagonalLength * 1.5,
		];
		camera.setPosition(newPosition[0], newPosition[1], newPosition[2]);
	}

	renderer.resetCameraClippingRange();
}

export const createGumball = (actorData: DigitalTwinData) => {
	const {gumball: oldGumball, setGumball} = useInteractionStore.getState();
	const {
		volume: {renderer, renderWindow},
	} = getViewports();

	if (oldGumball) {
		removeGumball();
	}

	const gumball = makeGumball(actorData);
	const localPosition = actorData.position;
	const actorRotationMatrix = vtkMatrixBuilder
		.buildFromDegree()
		.identity()
		.rotateX(actorData.rotation.x)
		.rotateY(actorData.rotation.y)
		.rotateZ(actorData.rotation.z)
		.getMatrix();
	rotateGumball({
		angles: actorRotationMatrix,
		gumball,
	});
	translateGumball({
		gumball,
		translation: localPosition,
		actorCenter: {x: 0, y: 0, z: 0},
	});

	setGumball(gumball);

	for (const handle of Object.values(gumball.rotationHandles)) {
		renderer?.addActor(handle.actor);
	}

	for (const handle of Object.values(gumball.translationHandles)) {
		renderer?.addActor(handle.actor);
	}

	for (const handles of Object.values(gumball.resizeHandles)) {
		for (const handle of handles) {
			renderer?.addActor(handle.actor);
		}
	}

	adjustCameraForGumball(actorData);
	renderWindow?.render();
};

export function createOutline(
	sourceActorData: DigitalTwinData,
): ActorAndSource {
	const sourceActor = sourceActorData.actor;
	const sourcePosition = getPosition(sourceActor);
	const {dimensions, type, rotation} = sourceActorData;

	let outlineData: ActorAndSource;

	switch (type) {
		case 'resect': {
			outlineData = makeCube({
				center: {x: 0, y: 0, z: 0},
				dimensions,
				color: [1, 1, 1],
			});

			break;
		}

		case 'drill': {
			outlineData = makeCylinder({
				center: {x: 0, y: 0, z: 0},
				dimensions,
				color: [1, 1, 1],
				resolution: 32,
			});

			break;
		}

		case 'ream': {
			const radius = Math.max(dimensions.x, dimensions.y, dimensions.z) / 2;
			const {
				volume: {camera},
			} = getViewports();

			if (!camera) {
				throw new Error('Camera not found');
			}

			const cameraPosition = camera.getPosition();
			const direction = [
				cameraPosition[0] - sourcePosition.x,
				cameraPosition[1] - sourcePosition.y,
				cameraPosition[2] - sourcePosition.z,
			];
			const length = Math.hypot(direction[0], direction[1], direction[2]);
			const normalizedDirection = direction.map((d) => d / length) as [
				number,
				number,
				number,
			];

			outlineData = makeCircle({
				center: [0, 0, 0],
				direction: normalizedDirection,
				radius,
				resolution: 100,
				color: [1, 1, 1],
			});
			break;
		}

		default: {
			throw new Error(`Unsupported digital twin type: ${type}`);
		}
	}

	const {actor, source} = outlineData;

	actor.setPosition(sourcePosition.x, sourcePosition.y, sourcePosition.z);

	if (type !== 'ream') {
		actor.rotateX(rotation.x);
		actor.rotateY(rotation.y);
		actor.rotateZ(rotation.z);
	}

	const property = actor.getProperty();
	property.setRepresentation(1); // Wireframe representation
	property.setLineWidth(3);
	property.setLighting(false);
	property.setOpacity(0.1);

	return {actor, source};
}

export const setActorOpacity = (actor: vtkActor, opacity: number) => {
	actor.getProperty().setOpacity(opacity);
};

export const setGumballHandlesOpacity = (
	gumball: GumballData,
	opacity: number,
	excludeHandle?: vtkActor,
) => {
	for (const handle of Object.values(gumball.rotationHandles)) {
		if (handle.actor !== excludeHandle) {
			handle.actor.getProperty().setOpacity(opacity);
		}
	}

	for (const handle of Object.values(gumball.translationHandles)) {
		if (handle.actor !== excludeHandle) {
			handle.actor.getProperty().setOpacity(opacity);
		}
	}

	for (const handles of Object.values(gumball.resizeHandles)) {
		for (const handle of handles) {
			if (handle.actor !== excludeHandle) {
				handle.actor.getProperty().setOpacity(opacity);
			}
		}
	}
};

export function rotateGumball({
	angles,
	gumball,
}: {
	angles: mat4;
	gumball: GumballData;
}) {
	const rotateHandle = (handle: vtkActor) => {
		setTransformationMatrix(handle, angles);
	};

	for (const handle of Object.values(gumball.rotationHandles)) {
		rotateHandle(handle.actor);
	}

	for (const handle of Object.values(gumball.translationHandles)) {
		rotateHandle(handle.actor);
	}

	for (const handles of Object.values(gumball.resizeHandles)) {
		for (const handle of handles) {
			rotateHandle(handle.actor);
		}
	}
}

export function translateGumball({
	gumball,
	translation,
	actorCenter,
}: {
	gumball: GumballData;
	translation: Vector3D;
	actorCenter: Vector3D;
}) {
	const newPosition = {
		x: actorCenter.x + translation.x,
		y: actorCenter.y + translation.y,
		z: actorCenter.z + translation.z,
	};

	for (const handle of Object.values(gumball.rotationHandles)) {
		setPosition(handle.actor, newPosition);
	}

	for (const handle of Object.values(gumball.translationHandles)) {
		setPosition(handle.actor, newPosition);
	}

	for (const handles of Object.values(gumball.resizeHandles)) {
		for (const handle of handles) {
			setPosition(handle.actor, newPosition);
		}
	}
}

export function resizeGumball(
	gumball: GumballData,
	actorData: DigitalTwinData,
) {
	const {dimensions} = actorData;

	const actorWidth = dimensions.x;
	const actorHeight = dimensions.y;
	const actorDepth = dimensions.z;

	// Gumball sizing
	const maxActorDimension = Math.max(actorWidth, actorHeight, actorDepth);
	const gumballScale = 2;
	const rotationHandleRadius = maxActorDimension;

	// Axis lengths for translation handles
	const xAxisLength = maxActorDimension * gumballScale;
	const yAxisLength = maxActorDimension * gumballScale;
	const zAxisLength = maxActorDimension * gumballScale;

	// Center position
	const centerX = getPosition(actorData.actor).x;
	const centerY = getPosition(actorData.actor).y;
	const centerZ = getPosition(actorData.actor).z;
	// eslint-disable-next-line no-warning-comments
	const centerArray = [centerX, centerY, centerZ]; // TODO: Make sure that you want to call it this way instead of getCenter()
	const center = {x: centerX, y: centerY, z: centerZ};

	// Update rotation handles
	for (const handle of Object.values(gumball.rotationHandles)) {
		const circleSource = handle.source as vtkCircleSource;
		circleSource.setRadius(rotationHandleRadius);
		circleSource.modified();
		setPosition(handle.actor, center);
	}

	// Update translation handles
	const axisLengths = [xAxisLength, yAxisLength, zAxisLength];
	for (const [index, axis] of AXES.entries()) {
		const handle = gumball.translationHandles[axis];
		const lineSource = handle.source as vtkLineSource;
		const axisLength = axisLengths[index];
		const direction = [0, 0, 0];
		direction[index] = 1;

		const startPoint = centerArray.map(
			(_c, index) => -(direction[index] * axisLength) / 2,
		) as [number, number, number];
		const endPoint = centerArray.map(
			(_c, index) => Number(direction[index] * axisLength) / 2,
		) as [number, number, number];

		lineSource.setPoint1(startPoint);
		lineSource.setPoint2(...endPoint);
		lineSource.modified();

		for (const {actor} of Object.values(gumball.translationHandles)) {
			setPosition(actor, center);
		}
	}

	// Update resize handles
	for (const [index, axis] of AXES.entries()) {
		for (const [handleIndex, handle] of gumball.resizeHandles[axis].entries()) {
			const direction = [0, 0, 0];
			direction[index] = handleIndex === 0 ? 1 : -1;
			const handleX = direction[0] * dimensions.x;
			const handleY = direction[1] * dimensions.y;
			const handleZ = direction[2] * dimensions.z;
			const resizeHandleSource = handle.source as vtkCubeSource;
			resizeHandleSource.setCenter(handleX, handleY, handleZ);
			resizeHandleSource.modified();

			for (const handles of Object.values(gumball.resizeHandles)) {
				for (const {actor} of handles) {
					setPosition(actor, center);
				}
			}
		}
	}

	// Ensure all actors are updated
	for (const {actor} of Object.values(gumball.rotationHandles)) {
		actor.modified();
	}

	for (const {actor} of Object.values(gumball.translationHandles)) {
		actor.modified();
	}

	for (const handles of Object.values(gumball.resizeHandles)) {
		for (const {actor} of handles) {
			actor.modified();
		}
	}
}

export const removeGumball = () => {
	const {gumball, setGumball} = useInteractionStore.getState();
	const {
		volume: {renderer, renderWindow},
	} = getViewports();

	if (gumball) {
		for (const {actor} of Object.values(gumball.rotationHandles)) {
			renderer?.removeActor(actor);
		}

		for (const {actor} of Object.values(gumball.translationHandles)) {
			renderer?.removeActor(actor);
		}

		for (const handles of Object.values(gumball.resizeHandles)) {
			for (const {actor} of handles) {
				renderer?.removeActor(actor);
			}
		}

		setGumball(undefined);
		renderWindow?.render();
	}
};

export function calculateGumballDimensions(actor: vtkActor) {
	const bounds = actor.getBounds();
	const width = bounds[1] - bounds[0];
	const height = bounds[3] - bounds[2];
	const depth = bounds[5] - bounds[4];
	const maxDimension = Math.max(width, height, depth);
	const center = actor.getCenter();

	return {
		width,
		height,
		depth,
		maxDimension,
		center,
		axisLengths: [width * 1.5, height * 1.5, depth * 1.5],
	};
}
