import {EVENTS, eventTarget, type VolumeViewport} from '@cornerstonejs/core';
import {Enums} from '@cornerstonejs/tools';
import {AnnotationAddedEventType} from '@cornerstonejs/tools/dist/cjs/types/EventTypes';
import {useEffect, useState} from 'react';

import {Annotation, AnnotationTool, cornerstone} from '@/library';
import {
	areElementsDefined as areCornerstoneElementsDefined,
	type ViewportElementKey,
} from '@/library/cornerstone';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';
import enableAndLoadCornerstoneViewports from '@/utils/enable-and-load-cornerstone-viewports';

const csToolsEvents = Enums.Events;

function useInitializeViewport({scan}: {scan: Scan}): {
	dicomImageLoadingProgress: number;
	volumeId: string;
} {
	const {
		annotations: {
			addAnnotation,
			updateAnnotation,
			removeAnnotation,
			setAreAnnotationsDirty,
		},
		viewports: {
			axialViewport,
			coronalViewport,
			sagittalViewport,
			setAreCornerstoneViewportsInitialized,
			setAxialViewport,
			setCoronalViewport,
			setSagittalViewport,
		},
	} = useGlobalState();

	const [volumeId, setVolumeId] = useState<string>('');
	const [imageIds, setImageIds] = useState<string[]>([]);
	const [dicomImageLoadingProgress, setDicomImageLoadingProgress] = useState(0);

	useEffect(() => {
		let loadedCount = 0;

		const imageLoadedHandler = () => {
			loadedCount += 1;
			setDicomImageLoadingProgress(loadedCount / imageIds.length);
		};

		eventTarget.addEventListener(EVENTS.IMAGE_LOADED, imageLoadedHandler);
		return () => {
			eventTarget.removeEventListener(EVENTS.IMAGE_LOADED, imageLoadedHandler);
		};
	}, [imageIds.length]);

	useEffect(() => {
		const initializeViewport = async () => {
			if (!areCornerstoneElementsDefined()) {
				throw new Error('Invalid cornerstone elements');
			}

			const {volumeId, imageIds: loadedImageIds} =
				await enableAndLoadCornerstoneViewports({
					scan,
				});
			setImageIds(loadedImageIds);
			const renderingEngine = cornerstone.renderingEngine.get();

			const axialViewport = renderingEngine.getViewport(
				cornerstone.viewportIds.axial,
			) as VolumeViewport;
			const coronalViewport = renderingEngine.getViewport(
				cornerstone.viewportIds.coronal,
			) as VolumeViewport;
			const sagittalViewport = renderingEngine.getViewport(
				cornerstone.viewportIds.sagittal,
			) as VolumeViewport;

			setVolumeId(volumeId);
			setAxialViewport(axialViewport);
			setCoronalViewport(coronalViewport);
			setSagittalViewport(sagittalViewport);
			setAreCornerstoneViewportsInitialized(true);
		};

		initializeViewport().catch(console.error);
	}, []);

	async function handleAnnotationEvent(event: AnnotationAddedEventType) {
		const {detail} = event;
		const {annotation} = detail;
		const {annotationUID} = annotation;

		if (
			['Crosshairs', 'Probe'].includes(annotation.metadata.toolName) ||
			annotationUID === undefined ||
			annotation.data.handles?.points === undefined
		) {
			return;
		}

		let imageIndex = 0;
		if (event.detail?.viewportId === 'CT_AXIAL' && axialViewport) {
			imageIndex = axialViewport.getCurrentImageIdIndex() ?? 0;
		} else if (event.detail?.viewportId === 'CT_CORONAL' && coronalViewport) {
			imageIndex = coronalViewport.getCurrentImageIdIndex() ?? 0;
		} else if (event.detail?.viewportId === 'CT_SAGITTAL' && sagittalViewport) {
			imageIndex = sagittalViewport.getCurrentImageIdIndex() ?? 0;
		}

		const points = annotation.data.handles.points.map((point: number[]) => ({
			x: point[0],
			y: point[1],
			z: point[2],
		}));

		let viewportId: ViewportElementKey = 'axial';

		if (event.detail.viewportId === 'CT_CORONAL') {
			viewportId = 'coronal';
		} else if (event.detail.viewportId === 'CT_SAGITTAL') {
			viewportId = 'sagittal';
		}

		const {toolName} = annotation.metadata;
		const data =
			/* eslint-disable-next-line @typescript-eslint/ban-ts-comment --
			 * This should work
			 */
			// @ts-expect-error
			annotation.data.cachedStats?.[
				'volumeId:cornerstoneStreamingImageVolume:CT_VOLUME_ID'
			] ?? {};

		const newAnnotation: Annotation = {
			id: annotationUID,
			viewportId,
			imageIndex,
			// @ts-expect-error -- TODO: Look into this. It seems wrong.
			points,
			data: {
				...((toolName === 'Angle' || toolName === 'CobbAngle') && {
					angle: data?.angle ?? 0,
				}),
				...(toolName === 'ArrowAnnotate' && {
					text: annotation.data.text ?? '',
				}),
				...(toolName === 'Bidirectional' && {
					length: data?.length ?? 0,
					unit: data?.unit ?? 0,
					width: data?.width ?? 0,
				}),
				...((toolName === 'EllipticalROI' || toolName === 'RectangleROI') && {
					area: data?.area ?? 0,
					areaUnit: data?.areaUnit ?? 0,
				}),
				...(toolName === 'Length' && {
					length: data?.length ?? 0,
				}),
			},
			note:
				toolName === 'ArrowAnnotate'
					? annotation.data.text
					: // @ts-expect-error -- TODO: Look into this. It seems wrong.
					  annotation.note,
			toolType: toolName as AnnotationTool,
			screenshotDataUrl: undefined,
		};

		switch (event.type) {
			case csToolsEvents.ANNOTATION_ADDED: {
				addAnnotation(newAnnotation);
				break;
			}

			case csToolsEvents.ANNOTATION_COMPLETED: {
				updateAnnotation(annotationUID, newAnnotation);
				break;
			}

			case csToolsEvents.ANNOTATION_MODIFIED: {
				updateAnnotation(annotationUID, newAnnotation);
				break;
			}

			case csToolsEvents.ANNOTATION_REMOVED: {
				removeAnnotation(annotationUID);
				break;
			}

			default: {
				throw new Error('Invalid event type');
			}
		}

		setAreAnnotationsDirty(true);
	}

	useEffect(() => {
		const addedEvent = csToolsEvents.ANNOTATION_ADDED;
		const completedEvent = csToolsEvents.ANNOTATION_COMPLETED;
		const updatedEvent = csToolsEvents.ANNOTATION_MODIFIED;
		const removedEvent = csToolsEvents.ANNOTATION_REMOVED;

		eventTarget.addEventListener(addedEvent, handleAnnotationEvent);
		eventTarget.addEventListener(completedEvent, handleAnnotationEvent);
		eventTarget.addEventListener(updatedEvent, handleAnnotationEvent);
		eventTarget.addEventListener(removedEvent, handleAnnotationEvent);

		return () => {
			eventTarget.removeEventListener(addedEvent, handleAnnotationEvent);
			eventTarget.removeEventListener(completedEvent, handleAnnotationEvent);
			eventTarget.removeEventListener(updatedEvent, handleAnnotationEvent);
			eventTarget.removeEventListener(removedEvent, handleAnnotationEvent);
		};
	}, [axialViewport, coronalViewport, handleAnnotationEvent, sagittalViewport]);

	return {
		dicomImageLoadingProgress,
		volumeId,
	};
}

export default useInitializeViewport;
