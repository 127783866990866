import {useEffect} from 'react';

import {isDefined, setSelectedResectionPlanePair} from '@/library';
import {useGlobalState} from '@/state';

export default function useSetPair() {
	const {
		customDefaults,
		resectionPlanes,
		user: {user},
	} = useGlobalState();
	useEffect(() => {
		async function apply() {
			if (
				customDefaults.resectionPlanes.pair.applied ||
				resectionPlanes.state !== 'ready' ||
				!isDefined(user)
			) {
				return;
			}

			const pair = user.customDefaults?.resectionPlanes?.pair;

			if (isDefined(pair) && pair !== resectionPlanes.selectedPairKey) {
				await setSelectedResectionPlanePair({pair});
			}

			customDefaults.resectionPlanes.pair.setApplied(true);
		}

		apply().catch(console.error);
	}, [
		customDefaults.resectionPlanes.pair.applied,
		resectionPlanes.state,
		user,
	]);
}
