import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {Draft} from 'immer';
import {
	type DigitalTwinMode,
	type DigitalTwinData,
	type DrillData,
	type ReamData,
	type ResectData,
	isDrillData,
	isReamData,
	isResectData,
} from '@/library/digital-twins';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

type State = {
	areDigitalTwinsDirty: boolean;
	digitalTwins: DigitalTwinData[];
	mode: DigitalTwinMode;
	selectedDigitalTwinId: string | undefined;
	state: 'ready' | 'loading' | 'updating' | 'error';
	outlineActor: vtkActor | undefined;
};

export type DigitalTwinsState = State;

type Actions = {
	addDigitalTwin: (digitalTwin: DigitalTwinData) => void;
	setAreDigitalTwinsDirty: (areDigitalTwinsDirty: boolean) => void;
	setDigitalTwin: (digitalTwin: DigitalTwinData) => void;
	setDigitalTwins: (digitalTwins: DigitalTwinData[]) => void;
	setMode: (mode: DigitalTwinMode) => void;
	setSelectedDigitalTwinId: (digitalTwinId: string) => void;
	setState: (state: State['state']) => void;
	setOutlineActor: (actor: vtkActor | undefined) => void;
	removeDigitalTwin: (digitalTwinId: string) => void;
	selectDigitalTwin: (digitalTwinId: string) => void;
	updateDigitalTwin: <T extends DigitalTwinData>(
		digitalTwinId: string,
		updates: Partial<T>,
	) => void;
	updateDrillData: (digitalTwinId: string, updates: Partial<DrillData>) => void;
	updateReamData: (digitalTwinId: string, updates: Partial<ReamData>) => void;
	updateResectData: (
		digitalTwinId: string,
		updates: Partial<ResectData>,
	) => void;
};

export const useDigitalTwinsStore = create(
	immer<State & Actions>((set) => ({
		areDigitalTwinsDirty: false,
		digitalTwins: [],
		mode: 'remaining',
		selectedDigitalTwinId: undefined,
		state: 'loading',
		outlineActor: undefined,
		addDigitalTwin(digitalTwin) {
			set((state) => {
				state.digitalTwins.push(digitalTwin);
			});
		},
		setAreDigitalTwinsDirty(areDigitalTwinsDirty) {
			set((state) => {
				state.areDigitalTwinsDirty = areDigitalTwinsDirty;
			});
		},
		setDigitalTwin(digitalTwin) {
			set((state) => {
				const index = state.digitalTwins.findIndex(
					(dt) => dt.id === digitalTwin.id,
				);
				if (index !== -1) {
					state.digitalTwins[index] = digitalTwin;
				}
			});
		},
		setDigitalTwins(digitalTwins) {
			set((state) => {
				state.digitalTwins = digitalTwins;
			});
		},
		setMode(mode) {
			set((state) => {
				state.mode = mode;
			});
		},
		setSelectedDigitalTwinId(id) {
			set((state) => {
				state.selectedDigitalTwinId = id;
			});
		},
		setState(newState: State['state']) {
			set((state) => {
				state.state = newState;
			});
		},
		setOutlineActor(actor) {
			set((state) => {
				state.outlineActor = actor;
			});
		},
		removeDigitalTwin(digitalTwinId) {
			set((state) => {
				state.digitalTwins = state.digitalTwins.filter(
					(dt) => dt.id !== digitalTwinId,
				);
			});
		},
		selectDigitalTwin(digitalTwinId) {
			set((state) => {
				state.selectedDigitalTwinId = digitalTwinId;
			});
		},

		// eslint-disable-next-line object-shorthand
		updateDigitalTwin: (digitalTwinId, updates) => {
			set((state) => {
				const index = state.digitalTwins.findIndex(
					(dt) => dt.id === digitalTwinId,
				);
				if (index !== -1) {
					const currentTwin = state.digitalTwins[index];
					const updatedTwin = {
						...currentTwin,
						...updates,
					} as Draft<DigitalTwinData>;

					// Type guard to ensure type-specific properties are updated correctly
					if (isDrillData(currentTwin) && isDrillData(updatedTwin)) {
						state.digitalTwins[index] = updatedTwin;
					} else if (isReamData(currentTwin) && isReamData(updatedTwin)) {
						state.digitalTwins[index] = updatedTwin;
					} else if (isResectData(currentTwin) && isResectData(updatedTwin)) {
						state.digitalTwins[index] = updatedTwin;
					} else {
						console.error('Invalid update: mismatched types');
					}
				}
			});
		},

		// eslint-disable-next-line object-shorthand
		updateResectData: (digitalTwinId: string, updates: Partial<ResectData>) => {
			set((state) => {
				const index = state.digitalTwins.findIndex(
					(dt) => dt.id === digitalTwinId && dt.type === 'resect',
				);
				if (index !== -1) {
					state.digitalTwins[index] = Object.assign(
						state.digitalTwins[index],
						updates,
					);
				}
			});
		},

		// eslint-disable-next-line object-shorthand
		updateDrillData: (digitalTwinId: string, updates: Partial<DrillData>) => {
			set((state) => {
				const index = state.digitalTwins.findIndex(
					(dt) => dt.id === digitalTwinId && dt.type === 'drill',
				);
				if (index !== -1) {
					const digitalTwin = state.digitalTwins[index] as DrillData;
					Object.assign(digitalTwin, updates);
				}
			});
		},

		// eslint-disable-next-line object-shorthand
		updateReamData: (digitalTwinId: string, updates: Partial<ReamData>) => {
			set((state) => {
				const index = state.digitalTwins.findIndex(
					(dt) => dt.id === digitalTwinId && dt.type === 'ream',
				);
				if (index !== -1) {
					const digitalTwin = state.digitalTwins[index] as ReamData;
					Object.assign(digitalTwin, updates);
				}
			});
		},
	})),
);
