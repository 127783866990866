import delay from 'delay';
import {useEffect} from 'react';

import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';

const useLoadAnnotations = ({scan}: {scan: Scan}) => {
	const {
		annotations: {addAnnotation, annotations, setNotes, state, setState},
		viewports: {axialViewport, coronalViewport, sagittalViewport},
	} = useGlobalState();

	useEffect(() => {
		async function loadAnnotations() {
			setState('updating');

			await delay(1);

			for (const annotation of scan.annotations.annotations) {
				addAnnotation(annotation);
			}

			setNotes(scan.annotations.notes);

			axialViewport?.render();
			coronalViewport?.render();
			sagittalViewport?.render();

			setState('ready');
		}

		if (
			state === 'loading' &&
			axialViewport &&
			coronalViewport &&
			sagittalViewport &&
			scan.annotations.annotations?.length
		) {
			loadAnnotations().catch(console.error);
		}
	}, [
		annotations,
		scan.annotations,
		setState,
		state,
		axialViewport,
		coronalViewport,
		sagittalViewport,
	]);
};

export default useLoadAnnotations;
