import {
	Card,
	CardContent,
	type ColorPaletteProp,
	FormControl,
	FormLabel,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemContent,
	ListItemDecorator,
	Typography,
} from '@mui/joy';
import {TrashIcon} from 'lucide-react';
import plur from 'plur';
import React, {type ReactNode, useEffect, useState} from 'react';
import {
	type Accept as DropzoneAccept,
	type FileRejection,
	useDropzone,
} from 'react-dropzone';
import {useList} from 'react-use';

import FileIcon from './file-icon';

export type Props = {
	readonly accept?: DropzoneAccept;
	readonly disabled?: boolean;
	readonly label?: ReactNode;
	readonly maxFiles?: number;
	readonly multiple?: boolean;
	readonly onChange?: (files: File[]) => void;
};

/**
 * Inspired by
 * [U.S. Web Design System's file input]{@link https://designsystem.digital.gov/components/file-input/}
 */
export default function FileInput({
	accept,
	disabled = false,
	label,
	maxFiles = 0,
	multiple = true,
	onChange,
}: Props) {
	const [
		acceptedFiles,
		{removeAt: removeAcceptedFileAt, set: setAcceptedFiles},
	] = useList<File>([]);
	const [fileRejections, {set: setFileRejections}] = useList<FileRejection>([]);

	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	useEffect(() => {
		setErrorMessage(fileRejections?.[0]?.errors?.[0]?.message);
	}, [fileRejections]);

	const {
		getRootProps: getDropzoneRootProps,
		getInputProps: getDropzoneInputProps,
		isDragActive: isDropzoneDragActive,
	} = useDropzone({
		accept,
		disabled,
		maxFiles,
		multiple,
		onDrop(acceptedFiles, fileRejections) {
			setAcceptedFiles(acceptedFiles);
			setFileRejections(fileRejections);
		},
	});

	useEffect(() => {
		onChange?.(acceptedFiles);
	}, [acceptedFiles]);

	function handleDeleteClickAt(
		index: number,
	): React.MouseEventHandler<HTMLButtonElement> {
		return function (event) {
			event.stopPropagation();
			removeAcceptedFileAt(index);
		};
	}

	let cardColor: ColorPaletteProp = 'neutral';

	if (isDropzoneDragActive) cardColor = 'primary';
	else if (errorMessage) cardColor = 'danger';

	return (
		<FormControl>
			{label && <FormLabel>File</FormLabel>}

			<Card
				invertedColors
				// @ts-expect-error -- This should be fine
				color={cardColor}
				sx={{
					borderStyle: 'dashed',
					cursor: 'pointer',
					padding: acceptedFiles.length > 0 ? 2 : 4,
				}}
				variant={isDropzoneDragActive ? 'solid' : 'outlined'}
				{...getDropzoneRootProps()}
			>
				<input {...getDropzoneInputProps()} />

				<CardContent
					sx={{alignItems: acceptedFiles.length > 0 ? 'normal' : 'center'}}
				>
					{acceptedFiles.length > 0 ? (
						<>
							<Typography level="title-md">
								Selected {plur('file', acceptedFiles.length)}
							</Typography>

							<List>
								{acceptedFiles.map((file, index) => (
									<ListItem key={file.name}>
										<ListItemDecorator>
											<FileIcon file={file} />
										</ListItemDecorator>

										<ListItemContent>{file.name}</ListItemContent>

										<ListItemDecorator>
											<IconButton onClick={handleDeleteClickAt(index)}>
												<TrashIcon />
											</IconButton>
										</ListItemDecorator>
									</ListItem>
								))}
							</List>
						</>
					) : (
						<>
							<Typography>
								Drag file here or{' '}
								<Link component="span">choose from folder</Link>
							</Typography>
							{errorMessage && (
								<Typography color="danger" fontWeight="bold">
									{errorMessage}
								</Typography>
							)}
						</>
					)}
				</CardContent>
			</Card>
		</FormControl>
	);
}
