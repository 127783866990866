import {useEffect, useState} from 'react';

import {useTools} from '@/hooks';
import {isDefined} from '@/library';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';

export default function useSetTool(scan: Scan) {
	const {
		user: {user},
		viewports,
	} = useGlobalState();
	const [applied, setApplied] = useState<boolean>(false);
	const tools = useTools({scan});

	useEffect(() => {
		if (
			applied ||
			!isDefined(scan) ||
			!isDefined(user) ||
			!viewports.areCornerstoneViewportsInitialized
		) {
			return;
		}

		tools.setTool(user.customDefaults?.tool);

		setApplied(true);
	}, [applied, scan, user, viewports.areCornerstoneViewportsInitialized]);
}
