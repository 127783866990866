import {
	Alert,
	Button,
	Divider,
	Dropdown,
	FormControl,
	FormHelperText,
	FormLabel,
	IconButton,
	Input,
	List,
	ListItem,
	ListItemButton,
	ListItemDecorator,
	Menu,
	MenuButton,
	MenuItem,
	Option,
	Select,
	type SelectProps,
	Stack,
	ToggleButtonGroup,
	type ToggleButtonGroupOwnProps,
	Tooltip,
	Typography,
} from '@mui/joy';
import {capitalCase} from 'change-case';
import delay from 'delay';
import {
	BoxIcon,
	CylinderIcon,
	MagnetIcon,
	Move3dIcon,
	MoveDiagonalIcon,
	MoveDownIcon,
	MoveDownLeftIcon,
	MoveHorizontalIcon,
	MoveLeftIcon,
	MoveRightIcon,
	MoveUpIcon,
	MoveUpRightIcon,
	MoveVerticalIcon,
	PenSquareIcon,
	PlusIcon,
	Rotate3dIcon,
	RulerIcon,
	Trash2Icon,
} from 'lucide-react';
import React, {type ReactNode} from 'react';

import {
	SliderAndNumberInput,
	type SliderAndNumberInputProps,
} from '@/components';
import {
	ResectionRemainingIcon,
	ResectionRemovedIcon,
	SphereIcon,
} from '@/icons';
import {
	Bone,
	DepthDirection,
	DigitalTwinMode,
	HeightDirection,
	WidthDirection,
	addDrill,
	addReam,
	addResect,
	applyDigitalTwins,
	bones,
	deleteDigitalTwin,
	revertDigitalTwinChanges,
	snapDigitalTwinToLandmark,
	digitalTwinModes,
	isDrillData,
	isReamData,
	isResectData,
	globalPositionFromLandmarkRelativePosition,
	positionRelativeToLandmark,
	updateDigitalTwinAffectedBone,
	updateDigitalTwinDepth,
	updateDigitalTwinHeight,
	updateDigitalTwinLabel,
	updateDigitalTwinMode,
	updateDigitalTwinOpacity,
	updateDigitalTwinPosition,
	updateDigitalTwinRadius,
	updateDigitalTwinRotation,
	updateDigitalTwinWidth,
	updateSelectedDigitalTwin,
} from '@/library';
import {type Scan} from '@/library/models';
import {getViewports} from '@/state/viewports';
import {useGlobalState} from '@/state';
import {type Axis} from '@/types';

import DirectionToggleButtonGroup from './direction-toggle-button-group';

type Props = {
	readonly areDigitalTwinsDirty: boolean;
	readonly onEditDigitalTwins: () => void;
	readonly onSave: () => void;
	readonly scan: Scan;
};

export default function DigitalTwins({
	areDigitalTwinsDirty,
	onEditDigitalTwins,
	onSave,
	scan,
}: Props) {
	const {
		digitalTwins: {
			digitalTwins,
			mode,
			selectedDigitalTwinId,
			setDigitalTwin,
			setState,
			state,
		},
		scan: {landmarks},
		viewports: {bounds},
	} = useGlobalState();

	const selectedDigitalTwin = digitalTwins.find(
		(digitalTwin) => digitalTwin.id === selectedDigitalTwinId,
	);

	const handleChangeSelectedDigitalTwin = async (id: string) => {
		if (id === selectedDigitalTwinId) return;

		setState('updating');

		await delay(1);

		updateSelectedDigitalTwin({
			id,
		});

		getViewports().volume?.render();

		setState('ready');
	};

	const handleDeleteDigitalTwin = async (id: string) => {
		setState('updating');

		await delay(1);

		deleteDigitalTwin(id);

		getViewports().volume.render();

		onEditDigitalTwins();

		setState('ready');
	};

	const handleChangeMode: ToggleButtonGroupOwnProps<DigitalTwinMode>['onChange'] =
		async (event, mode) => {
			if (!mode || !selectedDigitalTwin) return;

			setState('updating');

			await delay(1);

			updateDigitalTwinMode({
				mode,
			});

			getViewports().volume?.render();

			setState('ready');
		};

	const handleClickAddResect = async () => {
		setState('updating');

		await delay(1);

		addResect();

		getViewports().volume?.render();

		onEditDigitalTwins();

		setState('ready');
	};

	const handleClickAddDrill = async () => {
		setState('updating');

		await delay(1);

		addDrill();

		getViewports().volume?.render();

		onEditDigitalTwins();

		setState('ready');
	};

	const handleClickAddReam = async () => {
		setState('updating');

		await delay(1);

		addReam();

		getViewports().volume?.render();

		onEditDigitalTwins();

		setState('ready');
	};

	const handleChangeLabel: React.ChangeEventHandler<HTMLInputElement> = ({
		target: {value},
		// eslint-disable-next-line unicorn/consistent-function-scoping
	}) => {
		updateDigitalTwinLabel(value);
	};

	const handleChangeAffectedBone: SelectProps<Bone, false>['onChange'] = async (
		_event,
		newBone,
	) => {
		if (!newBone || !selectedDigitalTwin) return;

		const oldBone = selectedDigitalTwin?.bone;

		setState('updating');

		await delay(1);

		updateDigitalTwinAffectedBone({
			id: selectedDigitalTwin.id,
			newBone,
			oldBone,
		});

		getViewports().volume?.render();

		setState('ready');
	};

	const handleChangeDepth: (parameters?: {
		committed?: boolean;
	}) => (depth: number) => void =
		({committed} = {}) =>
		async (depth) => {
			if (
				Number.isNaN(depth) ||
				!selectedDigitalTwin ||
				!isResectData(selectedDigitalTwin)
			)
				return;

			updateDigitalTwinDepth({
				extensionDirection: selectedDigitalTwin.depthDirection,
				id: selectedDigitalTwin.id,
				depth,
			});

			getViewports().volume?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangeHeight: (parameters?: {
		committed?: boolean;
	}) => (height: number) => void =
		({committed} = {}) =>
		async (height) => {
			if (
				Number.isNaN(height) ||
				!selectedDigitalTwin ||
				!(isDrillData(selectedDigitalTwin) || isResectData(selectedDigitalTwin))
			)
				return;

			updateDigitalTwinHeight({
				extensionDirection: selectedDigitalTwin.heightDirection,
				id: selectedDigitalTwin.id,
				height,
			});

			getViewports().volume?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangeOpacity: SliderAndNumberInputProps['onChange'] = (
		opacity,
	) => {
		if (Number.isNaN(opacity) || !selectedDigitalTwin) return;

		opacity /= 100;

		updateDigitalTwinOpacity({
			id: selectedDigitalTwin.id,
			opacity,
		});

		getViewports().volume?.render();
	};

	const handleChangeRadius: (parameters?: {
		committed?: boolean;
	}) => (radius: number) => void =
		({committed} = {}) =>
		async (radius) => {
			if (
				Number.isNaN(radius) ||
				!selectedDigitalTwin ||
				!(isDrillData(selectedDigitalTwin) || isReamData(selectedDigitalTwin))
			)
				return;

			updateDigitalTwinRadius({id: selectedDigitalTwin.id, radius});

			getViewports().volume?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangeRotation: (parameters: {
		axis: Axis;
		committed?: boolean;
	}) => SliderAndNumberInputProps['onChange'] =
		({axis, committed}) =>
		async (angle) => {
			if (
				Number.isNaN(angle) ||
				!selectedDigitalTwin ||
				!(isDrillData(selectedDigitalTwin) || isResectData(selectedDigitalTwin))
			) {
				return;
			}

			updateDigitalTwinRotation({
				id: selectedDigitalTwin.id,
				rotation: {
					...selectedDigitalTwin.rotation,
					[axis]: angle,
				},
			});

			getViewports().volume?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangeWidth: (parameters?: {
		committed?: boolean;
	}) => (width: number) => void =
		({committed} = {}) =>
		async (width) => {
			if (
				Number.isNaN(width) ||
				!selectedDigitalTwin ||
				!isResectData(selectedDigitalTwin)
			)
				return;

			updateDigitalTwinWidth({
				extensionDirection: selectedDigitalTwin.widthDirection,
				id: selectedDigitalTwin.id,
				width,
			});

			getViewports().volume?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleChangePosition: (parameters: {
		axis: Axis;
		committed?: boolean;
	}) => SliderAndNumberInputProps['onChange'] =
		({axis, committed}) =>
		async (value) => {
			if (Number.isNaN(value) || !selectedDigitalTwin) return;

			const relativePosition = positionRelativeToLandmark(
				selectedDigitalTwin.landmarkId,
				selectedDigitalTwin.position,
			);

			relativePosition[axis] = value;

			const globalPosition = globalPositionFromLandmarkRelativePosition(
				selectedDigitalTwin.landmarkId,
				relativePosition,
			);

			updateDigitalTwinPosition({
				id: selectedDigitalTwin.id,
				position: {
					...globalPosition,
				},
			});

			getViewports().volume?.render();

			if (committed) await applyDigitalTwins();
		};

	const handleSelectLandmark: (parameters: {
		id: string;
	}) => React.MouseEventHandler<HTMLDivElement> =
		({id}) =>
		async () => {
			if (!selectedDigitalTwin?.bone) return;

			const landmark = landmarks.primary.find((landmark) => landmark.id === id);

			if (!landmark) return;

			setState('updating');

			await delay(1);

			snapDigitalTwinToLandmark(landmark);

			getViewports().volume?.render();

			onEditDigitalTwins();

			setState('ready');
		};

	const dimensionsInputs: Array<{
		type: 'height' | 'width' | 'depth' | 'radius';
		label: string;
		max: number;
		min: number;
		onChange: (value: number) => void;
		onChangeCommitted: (value: number) => void;
		value: number;
		directionOptions?: Array<{
			value: HeightDirection | WidthDirection | DepthDirection;
			icon: ReactNode;
		}>;
		selectedDirection?: HeightDirection | WidthDirection | DepthDirection;
		onHeightDirectionChange?: (newDirection: HeightDirection) => void;
		onWidthDirectionChange?: (newDirection: WidthDirection) => void;
		onDepthDirectionChange?: (newDirection: DepthDirection) => void;
	}> = [];

	if (selectedDigitalTwin) {
		if (isDrillData(selectedDigitalTwin)) {
			dimensionsInputs.push(
				{
					directionOptions: [
						{value: 'down', icon: <MoveDownIcon />},
						{value: 'up', icon: <MoveUpIcon />},
						{value: 'upAndDown', icon: <MoveVerticalIcon />},
					],
					label: 'Height',
					max: 75,
					min: 1,
					onChange: handleChangeHeight(),
					onChangeCommitted: handleChangeHeight({committed: true}),
					onHeightDirectionChange(direction) {
						setDigitalTwin({
							...selectedDigitalTwin,
							heightDirection: direction,
						});
					},
					selectedDirection: selectedDigitalTwin.heightDirection,
					type: 'height',
					value: selectedDigitalTwin.height,
				},
				{
					type: 'radius',
					label: 'Radius',
					max: 50,
					min: 1,
					onChange: handleChangeRadius(),
					onChangeCommitted: handleChangeRadius({committed: true}),
					value: selectedDigitalTwin.radius,
				},
			);
		}

		if (isResectData(selectedDigitalTwin)) {
			dimensionsInputs.push(
				{
					directionOptions: [
						{value: 'down', icon: <MoveDownIcon />},
						{value: 'up', icon: <MoveUpIcon />},
						{value: 'upAndDown', icon: <MoveVerticalIcon />},
					],
					label: 'S/I',
					max: 75,
					min: 1,
					onChange: handleChangeHeight(),
					onChangeCommitted: handleChangeHeight({committed: true}),
					onHeightDirectionChange(direction) {
						setDigitalTwin({
							...selectedDigitalTwin,
							heightDirection: direction,
						});
					},
					selectedDirection: selectedDigitalTwin.heightDirection,
					type: 'height',
					value: selectedDigitalTwin.height,
				},
				{
					directionOptions: [
						{value: 'left', icon: <MoveLeftIcon />},
						{value: 'right', icon: <MoveRightIcon />},
						{value: 'leftAndRight', icon: <MoveHorizontalIcon />},
					],
					label: 'M/L',
					max: 100,
					min: 1,
					onChange: handleChangeWidth(),
					onChangeCommitted: handleChangeWidth({committed: true}),
					onWidthDirectionChange(direction) {
						setDigitalTwin({
							...selectedDigitalTwin,
							widthDirection: direction,
						});
					},
					selectedDirection: selectedDigitalTwin.widthDirection,
					type: 'width',
					value: selectedDigitalTwin.width,
				},
				{
					directionOptions: [
						{value: 'front', icon: <MoveDownLeftIcon />},
						{value: 'back', icon: <MoveUpRightIcon />},
						{value: 'frontAndBack', icon: <MoveDiagonalIcon />},
					],
					label: 'A/P',
					max: 100,
					min: 1,
					onChange: handleChangeDepth(),
					onChangeCommitted: handleChangeDepth({committed: true}),
					onDepthDirectionChange(direction) {
						setDigitalTwin({...selectedDigitalTwin, depthDirection: direction});
					},
					selectedDirection: selectedDigitalTwin.depthDirection,
					type: 'depth',
					value: selectedDigitalTwin.depth,
				},
			);
		}

		if (isReamData(selectedDigitalTwin)) {
			dimensionsInputs.push({
				type: 'radius',
				label: 'Radius',
				max: 50,
				min: 1,
				onChange: handleChangeRadius(),
				onChangeCommitted: handleChangeRadius({committed: true}),
				value: selectedDigitalTwin.radius,
			});
		}
	}

	const positionInputs: Array<{
		axis: Axis;
		label: string;
		max: number;
		min: number;
	}> = [
		{
			axis: 'x',
			label: 'M/L',
			max: 50,
			min: -50,
		},
		{
			axis: 'y',
			label: 'A/P',
			max: 50,
			min: -50,
		},
		{
			axis: 'z',
			label: 'S/I',
			max: 50,
			min: -50,
		},
	];

	const rotationInputs: Array<{axis: 'x' | 'y' | 'z'; label: string}> = [
		{
			axis: 'x',
			label: 'Ext/Flex',
		},
		{
			axis: 'y',
			label: 'Var/Val',
		},
		{
			axis: 'z',
			label: 'Int/Ext',
		},
	];

	const handleRevertDigitalTwinChanges = async () => {
		setState('updating');

		await delay(1);

		revertDigitalTwinChanges({
			scan,
		});

		getViewports().volume?.render();

		setState('ready');
	};

	return (
		<Stack spacing={4}>
			{/* Dirty digital twins alert / actions */}
			{areDigitalTwinsDirty && (
				<Alert>
					<Stack spacing={2}>
						<span>You&rsquo;ve made changes to the Digital Twins.</span>

						<Stack direction="row" spacing={2}>
							<Button
								color="danger"
								size="sm"
								variant="outlined"
								onClick={async () => {
									await handleRevertDigitalTwinChanges();
								}}
							>
								Revert Changes
							</Button>
							<Button size="sm" onClick={onSave}>
								Save Changes
							</Button>
						</Stack>
					</Stack>
				</Alert>
			)}
			{/* Mode */}
			<Stack spacing={1}>
				<Typography level="title-md">Mode</Typography>

				<ToggleButtonGroup<DigitalTwinMode>
					disabled={digitalTwins.length === 0 || state !== 'ready'}
					value={mode}
					onChange={handleChangeMode}
				>
					{digitalTwinModes.map((mode) => {
						let icon: ReactNode;

						if (mode === 'remaining') {
							icon = <ResectionRemainingIcon />;
						} else if (mode === 'removed') {
							icon = <ResectionRemovedIcon />;
						}

						return (
							<Button key={mode} startDecorator={icon} value={mode}>
								{capitalCase(mode)}
							</Button>
						);
					})}
				</ToggleButtonGroup>
			</Stack>

			{/* Digital twins object list */}
			<Stack spacing={1}>
				<Typography level="title-md">Digital Twins</Typography>

				<List>
					{digitalTwins.map((digitalTwin) => {
						const selected = digitalTwin.id === selectedDigitalTwinId;

						return (
							<ListItem key={digitalTwin.id}>
								<ListItemButton
									selected={selected}
									onClick={async () =>
										handleChangeSelectedDigitalTwin(digitalTwin.id)
									}
								>
									<ListItemDecorator>
										<PenSquareIcon />
									</ListItemDecorator>

									<Stack
										direction="row"
										flexGrow={1}
										alignItems="center"
										justifyContent="space-between"
									>
										{digitalTwin.label}
										<Tooltip color="primary" title="Delete Digital Twin">
											<IconButton
												onClick={async (event) => {
													event.stopPropagation();
													await handleDeleteDigitalTwin(digitalTwin.id);
												}}
											>
												<Trash2Icon />
											</IconButton>
										</Tooltip>
									</Stack>
								</ListItemButton>
							</ListItem>
						);
					})}

					{/* Add digital twin */}
					<ListItem>
						<Dropdown>
							<MenuButton slots={{root: ListItemButton}}>
								<ListItemDecorator>
									<PlusIcon />
								</ListItemDecorator>
								Add Digital Twin
							</MenuButton>

							<Menu placement="bottom-start">
								<MenuItem onClick={handleClickAddReam}>
									<ListItemDecorator>
										<SphereIcon />
									</ListItemDecorator>
									Add Ream
								</MenuItem>
								<MenuItem onClick={handleClickAddDrill}>
									<ListItemDecorator>
										<CylinderIcon />
									</ListItemDecorator>
									Add Drill
								</MenuItem>
								<MenuItem onClick={handleClickAddResect}>
									<ListItemDecorator>
										<BoxIcon />
									</ListItemDecorator>
									Add Resect
								</MenuItem>
							</Menu>
						</Dropdown>
					</ListItem>
				</List>
			</Stack>

			{/* Selected digital twin settings */}
			{selectedDigitalTwin && bounds && landmarks && (
				<Stack spacing={4}>
					{/* Name */}
					<FormControl>
						<FormLabel>Name</FormLabel>
						<Input
							value={selectedDigitalTwin.label}
							onChange={handleChangeLabel}
						/>
					</FormControl>

					{/* Bone */}
					<FormControl>
						<FormLabel>Bone</FormLabel>

						<Select<Bone>
							value={selectedDigitalTwin.bone}
							onChange={handleChangeAffectedBone}
						>
							{bones.map((bone) => (
								<Option key={bone} value={bone}>
									{capitalCase(bone)}
								</Option>
							))}
						</Select>

						<FormHelperText>
							Bone which the digital twin should apply to
						</FormHelperText>
					</FormControl>

					{/* Snap to landmark */}
					<FormControl>
						<Dropdown>
							<MenuButton>
								<MagnetIcon /> &nbsp; Snap to landmark
							</MenuButton>

							<Menu>
								{landmarks.primary
									.filter(({id}) => id.includes(selectedDigitalTwin.bone))
									.map((landmark) => (
										<MenuItem
											key={landmark.id}
											onClick={handleSelectLandmark({id: landmark.id})}
										>
											{capitalCase(landmark.id)}
										</MenuItem>
									))}
							</Menu>

							<FormHelperText>
								Snap digital twin position to landmark
							</FormHelperText>
						</Dropdown>
					</FormControl>

					<Divider />

					{/* Positions */}
					<Typography level="title-md" startDecorator={<Move3dIcon />}>
						Positions
					</Typography>

					{positionInputs.map(({axis, label, max, min}) => (
						<Stack key={axis} spacing={1}>
							<Typography level="title-sm">{label}</Typography>

							<SliderAndNumberInput
								endDecorator="mm"
								formatOptions={{
									maximumFractionDigits: 0,
								}}
								label={label}
								max={max}
								min={min}
								testId={`position-${label}`}
								track={false}
								value={
									positionRelativeToLandmark(
										selectedDigitalTwin.landmarkId,
										selectedDigitalTwin.position,
									)[axis]
								}
								onNumberInputChange={handleChangePosition({
									axis,
									committed: true,
								})}
								onSliderChange={handleChangePosition({axis})}
								onSliderChangeCommitted={handleChangePosition({
									axis,
									committed: true,
								})}
							/>
						</Stack>
					))}

					{/* Rotations */}
					{(selectedDigitalTwin.type === 'drill' ||
						selectedDigitalTwin.type === 'resect') && (
						<>
							<Divider />

							<Typography level="title-md" startDecorator={<Rotate3dIcon />}>
								Rotations
							</Typography>

							{rotationInputs.map(({axis, label}) => (
								<Stack key={axis} spacing={1}>
									<Typography level="title-sm">{label}</Typography>

									<SliderAndNumberInput
										endDecorator="&deg;"
										formatOptions={{
											maximumFractionDigits: 0,
										}}
										label={label}
										max={90}
										min={-90}
										testId={`rotation-${label}`}
										track={false}
										value={selectedDigitalTwin.rotation[axis]}
										onNumberInputChange={handleChangeRotation({
											axis,
											committed: true,
										})}
										onSliderChange={handleChangeRotation({axis})}
										onSliderChangeCommitted={handleChangeRotation({
											axis,
											committed: true,
										})}
									/>
								</Stack>
							))}
						</>
					)}

					<Divider />

					{/* Dimensions */}
					<Typography level="title-md" startDecorator={<RulerIcon />}>
						Dimensions
					</Typography>

					{dimensionsInputs.map((input) => (
						<Stack key={input.label} spacing={1}>
							<Typography level="title-sm">{input.label}</Typography>
							{input.directionOptions && (
								<DirectionToggleButtonGroup
									disabled={!selectedDigitalTwin}
									options={input.directionOptions}
									selectedDirection={input.selectedDirection!}
									onHeightDirectionChange={input.onHeightDirectionChange}
									onWidthDirectionChange={input.onWidthDirectionChange}
									onDepthDirectionChange={input.onDepthDirectionChange}
								/>
							)}
							<SliderAndNumberInput
								endDecorator="mm"
								formatOptions={{maximumFractionDigits: 0}}
								label={input.label}
								max={input.max}
								min={input.min}
								testId={`dimension-${input.label}`}
								value={input.value}
								onNumberInputChange={input.onChangeCommitted}
								onSliderChange={input.onChange}
								onSliderChangeCommitted={input.onChangeCommitted}
							/>
						</Stack>
					))}

					<Divider />

					{/* Opacity */}
					<Stack spacing={1}>
						<Typography level="title-sm">Opacity</Typography>

						<SliderAndNumberInput
							endDecorator="%"
							formatOptions={{
								maximumFractionDigits: 0,
							}}
							label="Opacity"
							max={100}
							min={0}
							value={selectedDigitalTwin.opacity * 100}
							onChange={handleChangeOpacity}
						/>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}
