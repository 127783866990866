import {useEffect, useState} from 'react';

import {getViewports} from '@/state/viewports';
import {useGlobalState} from '@/state';
import {useDensityMapStore} from '@/state/density-map';
import {
	type CheckboxChangeHandler,
	type PointCloud as BasePointCloud,
} from '@/types';

type PointCloud = BasePointCloud & {
	handleVisibilityChange: CheckboxChangeHandler;
};

export default function usePointCloudsVisibility(): {
	all: {
		isIndeterminate: boolean;
		isVisible: boolean;
		handleVisibilityChange: CheckboxChangeHandler;
	};
	areLoaded: boolean;
	individual: PointCloud[];
} {
	const {
		densityMap: {pointClouds, updatePointCloud},
	} = useGlobalState();
	const [individualPointClouds, setIndividualPointClouds] = useState<
		PointCloud[]
	>([]);
	const [isAllPointCloudsVisible, setIsAllPointCloudsVisible] =
		useState<boolean>(true);

	const areAllIndividualPointCloudsHidden = individualPointClouds.every(
		(cloud) => !cloud.isVisible,
	);
	const areAllIndividualPointCloudsVisible = individualPointClouds.every(
		(cloud) => cloud.isVisible,
	);

	// Synchronize all point clouds visibility when all or none of the individual
	// point clouds become visible
	useEffect(() => {
		if (areAllIndividualPointCloudsHidden) {
			setIsAllPointCloudsVisible(false);
		} else if (areAllIndividualPointCloudsVisible) {
			setIsAllPointCloudsVisible(true);
		}
	}, [individualPointClouds]);

	// Synchronize individual point clouds with global state
	useEffect(() => {
		setIndividualPointClouds(
			pointClouds.map((cloud) => ({
				...cloud,
				handleVisibilityChange({target: {checked: isVisible}}) {
					updatePointCloudVisibility({id: cloud.id, isVisible});
				},
			})),
		);
	}, [pointClouds]);

	const handleAllPointCloudsVisibilityChange: CheckboxChangeHandler = ({
		target: {checked: isVisible},
	}) => {
		for (const pointCloud of individualPointClouds) {
			updatePointCloudVisibility({id: pointCloud.id, isVisible});
		}

		setIsAllPointCloudsVisible(isVisible);
	};

	function updatePointCloudVisibility({
		id,
		isVisible,
	}: {
		id: string;
		isVisible: boolean;
	}) {
		const {pointClouds} = useDensityMapStore.getState();
		const pointCloud = pointClouds.find((pointCloud) => pointCloud.id === id);

		if (!pointCloud) return;

		pointCloud.actor.setVisibility(isVisible);
		const {volume: volumeViewport} = getViewports();
		volumeViewport?.render();

		updatePointCloud({id, isVisible});
	}

	return {
		all: {
			handleVisibilityChange: handleAllPointCloudsVisibilityChange,
			isIndeterminate:
				!areAllIndividualPointCloudsHidden &&
				!areAllIndividualPointCloudsVisible,
			isVisible: isAllPointCloudsVisible,
		},
		areLoaded: individualPointClouds.length > 0,
		individual: individualPointClouds,
	};
}
