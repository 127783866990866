/* eslint-disable @typescript-eslint/ban-ts-comment, new-cap */

// @ts-nocheck

import vtkCellArray from '@kitware/vtk.js/Common/Core/CellArray';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import '@kitware/vtk.js/IO/Core/DataAccessHelper/LiteHttpDataAccessHelper';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import DataAccessHelper from '@kitware/vtk.js/IO/Core/DataAccessHelper';
import macro from '@kitware/vtk.js/macros';

import * as firebase from '@/library/firebase';

const {vtkErrorMacro} = macro;
let decoderModule = {};

// ----------------------------------------------------------------------------
// static methods
// ----------------------------------------------------------------------------

/**
 * Load the WASM decoder from url and set the decoderModule
 * @param url
 * @param binaryName
 * @return {Promise<boolean>}
 */
async function setWasmBinary(url, binaryName) {
	const dracoDecoderType = {};

	return new Promise((resolve, reject) => {
		dracoDecoderType.wasmBinaryFile = binaryName;

		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'arraybuffer';

		xhr.addEventListener('load', () => {
			if (xhr.status === 200) {
				dracoDecoderType.wasmBinary = xhr.response;
				// Use Promise.resolve to be compatible with versions before Draco 1.4.0
				Promise.resolve(window.DracoDecoderModule(dracoDecoderType)).then(
					(module) => {
						decoderModule = module;
						resolve(true);
					},
					reject,
				);
			} else {
				reject(new Error(`WASM binary could not be loaded: ${xhr.statusText}`));
			}
		});

		xhr.send();
	});
}

function setDracoDecoder(createDracoModule) {
	decoderModule = createDracoModule({});
}

function getDracoDecoder() {
	return decoderModule;
}

// ----------------------------------------------------------------------------
// vtkDracoReader methods
// ----------------------------------------------------------------------------

function decodeAttribute(
	draco,
	decoder,
	dracoGeometry,
	attributeName,
	attributeType,
	attribute,
) {
	const numberComponents = attribute.num_components();
	const numberPoints = dracoGeometry.num_points();
	const numberValues = numberPoints * numberComponents;
	let ptr;
	let array;
	let dataSize;

	switch (attributeType) {
		case 'Float32Array': {
			dataSize = numberValues * 4;
			ptr = draco._malloc(dataSize);
			decoder.GetAttributeDataArrayForAllPoints(
				dracoGeometry,
				attribute,
				draco.DT_FLOAT32,
				dataSize,
				ptr,
			);
			array = [...new Float32Array(draco.HEAPF32.buffer, ptr, numberValues)];
			draco._free(ptr);
			break;
		}

		case 'Int8Array': {
			ptr = draco._malloc(numberValues);
			decoder.GetAttributeDataArrayForAllPoints(
				dracoGeometry,
				attribute,
				draco.DT_INT8,
				numberValues,
				ptr,
			);
			array = [...new Int8Array(draco.HEAP8.buffer, ptr, numberValues)];
			draco._free(ptr);
			break;
		}

		case 'Int16Array': {
			dataSize = numberValues * 2;
			ptr = draco._malloc(dataSize);
			decoder.GetAttributeDataArrayForAllPoints(
				dracoGeometry,
				attribute,
				draco.DT_INT16,
				dataSize,
				ptr,
			);
			array = [...new Int16Array(draco.HEAP16.buffer, ptr, numberValues)];
			draco._free(ptr);
			break;
		}

		case 'Int32Array': {
			dataSize = numberValues * 4;
			ptr = draco._malloc(dataSize);
			decoder.GetAttributeDataArrayForAllPoints(
				dracoGeometry,
				attribute,
				draco.DT_INT32,
				dataSize,
				ptr,
			);
			array = [...new Int32Array(draco.HEAP32.buffer, ptr, numberValues)];
			draco._free(ptr);
			break;
		}

		case 'Uint8Array': {
			ptr = draco._malloc(numberValues);
			decoder.GetAttributeDataArrayForAllPoints(
				dracoGeometry,
				attribute,
				draco.DT_UINT8,
				numberValues,
				ptr,
			);
			array = [...new Uint8Array(draco.HEAPU8.buffer, ptr, numberValues)];
			draco._free(ptr);
			break;
		}

		case 'Uint16Array': {
			dataSize = numberValues * 2;
			ptr = draco._malloc(dataSize);
			decoder.GetAttributeDataArrayForAllPoints(
				dracoGeometry,
				attribute,
				draco.DT_UINT16,
				dataSize,
				ptr,
			);
			array = [...new Uint16Array(draco.HEAPU16.buffer, ptr, numberValues)];
			draco._free(ptr);
			break;
		}

		case 'Uint32Array': {
			dataSize = numberValues * 4;
			ptr = draco._malloc(dataSize);
			decoder.GetAttributeDataArrayForAllPoints(
				dracoGeometry,
				attribute,
				draco.DT_UINT32,
				dataSize,
				ptr,
			);
			array = [...new Uint32Array(draco.HEAPU32.buffer, ptr, numberValues)];
			draco._free(ptr);
			break;
		}

		default: {
			throw new Error('THREE.DRACOLoader: Unexpected attribute type.');
		}
	}

	return {
		name: attributeName,
		array,
		itemSize: numberComponents,
	};
}

function decodeBuffer(buffer) {
	const byteArray = new Int8Array(buffer);
	const draco = decoderModule;
	const decoder = new decoderModule.Decoder();
	const decoderBuffer = new decoderModule.DecoderBuffer();
	decoderBuffer.Init(byteArray, byteArray.length);

	const geometryType = decoder.GetEncodedGeometryType(decoderBuffer);

	let dracoGeometry;
	let status;
	if (geometryType === decoderModule.TRIANGULAR_MESH) {
		dracoGeometry = new decoderModule.Mesh();
		status = decoder.DecodeBufferToMesh(decoderBuffer, dracoGeometry);
	} else if (geometryType === decoderModule.POINT_CLOUD) {
		dracoGeometry = new decoderModule.PointCloud();
		status = decoder.DecodeBufferToPointCloud(decoderBuffer, dracoGeometry);
	} else {
		const errorMessage = 'Error: Unknown geometry type.';
		console.error(errorMessage);
	}

	if (!status.ok() || dracoGeometry.ptr === 0) {
		vtkErrorMacro(`Could not decode Draco file: ${status.error_msg()}`);
	}

	const geometry = {index: undefined, attributes: []};

	const attributeIds = {
		position: 'POSITION',
		normal: 'NORMAL',
		color: 'COLOR',
		uv: 'TEX_COORD',
	};

	const attributeTypes = {
		position: 'Float32Array',
		normal: 'Float32Array',
		color: 'Float32Array',
		uv: 'Float32Array',
	};

	// Gather all vertex attributes.
	for (const attributeKey of Object.keys(attributeIds)) {
		const attributeType = attributeTypes[attributeKey];
		let attribute;
		let attributeId;

		// A Draco file may be created with default vertex attributes, whose attribute IDs
		// are mapped 1:1 from their semantic name (POSITION, NORMAL, ...). Alternatively,
		// a Draco file may contain a custom set of attributes, identified by known unique
		// IDs. glTF files always do the latter, and `.drc` files typically do the former.
		const useUniqueIds = false;
		if (useUniqueIds) {
			attributeId = attributeIds[attributeKey];
			attribute = decoder.GetAttributeByUniqueId(dracoGeometry, attributeId);
		} else {
			attributeId = decoder.GetAttributeId(
				dracoGeometry,
				draco[attributeIds[attributeKey]],
			);

			if (attributeId === -1) {
				continue;
			}

			attribute = decoder.GetAttribute(dracoGeometry, attributeId);
		}

		geometry.attributes.push(
			decodeAttribute(
				draco,
				decoder,
				dracoGeometry,
				attributeKey,
				attributeType,
				attribute,
			),
		);
	}

	// Add index.
	if (geometryType === draco.TRIANGULAR_MESH) {
		// Generate mesh faces.
		const numberFaces = dracoGeometry.num_faces();
		const numberIndices = numberFaces * 3;
		const dataSize = numberIndices * 4;
		const ptr = draco._malloc(dataSize);
		decoder.GetTrianglesUInt32Array(dracoGeometry, dataSize, ptr);
		const index = [
			...new Uint32Array(draco.HEAPU32.buffer, ptr, numberIndices),
		];
		draco._free(ptr);

		geometry.index = {array: index, itemSize: 1};
	}

	decoderModule.destroy(decoderBuffer);
	decoderModule.destroy(decoder);
	return dracoGeometry;
}

function getDracoAttributeAsFloat32Array(dracoGeometry, attributeId) {
	const decoder = new decoderModule.Decoder();
	const attribute = decoder.GetAttribute(dracoGeometry, attributeId);
	const numberOfComponents = attribute.num_components();
	const numberOfPoints = dracoGeometry.num_points();

	const attributeData = new decoderModule.DracoFloat32Array();
	decoder.GetAttributeFloatForAllPoints(
		dracoGeometry,
		attribute,
		attributeData,
	);

	let index = numberOfPoints * numberOfComponents;
	const attributeArray = new Float32Array(index);
	while (index--) {
		attributeArray[index] = attributeData.GetValue(index);
	}

	return attributeArray;
}

function getPolyDataFromDracoGeometry(dracoGeometry) {
	const decoder = new decoderModule.Decoder();

	// Get position attribute ID
	const positionAttributeId = decoder.GetAttributeId(
		dracoGeometry,
		decoderModule.POSITION,
	);

	if (positionAttributeId === -1) {
		console.error('No position attribute found in the decoded model.');
		decoderModule.destroy(decoder);
		decoderModule.destroy(dracoGeometry);
		return;
	}

	const positionArray = getDracoAttributeAsFloat32Array(
		dracoGeometry,
		positionAttributeId,
		decoderModule,
	);

	let polyData;
	// Read indices
	if (dracoGeometry.num_faces) {
		let index = dracoGeometry.num_faces();
		const indices = new Uint32Array(index * 4);
		const indicesArray = new decoderModule.DracoInt32Array();
		while (index--) {
			decoder.GetFaceFromMesh(dracoGeometry, index, indicesArray);
			const chunkIndex = index * 4;
			indices[chunkIndex] = 3;
			indices[chunkIndex + 1] = indicesArray.GetValue(0);
			indices[chunkIndex + 2] = indicesArray.GetValue(1);
			indices[chunkIndex + 3] = indicesArray.GetValue(2);
		}

		// Create polyData and add positions and indinces
		const cellArray = vtkCellArray.newInstance({values: indices});
		polyData = vtkPolyData.newInstance({polys: cellArray});
		polyData.getPoints().setData(positionArray);
	} else {
		// POINT CLOUD
		polyData = vtkPolyData.newInstance();

		// Hand create a point cloud
		const numberPts = positionArray.length / 3;

		// Points
		polyData.getPoints().setData(positionArray);

		// Cells
		const verts = new Uint32Array(numberPts + 1);
		polyData.getVerts().setData(verts, 1);

		// Generate point connectivity
		//
		verts[0] = numberPts;
		for (let index = 0; index < numberPts; index++) {
			verts[index + 1] = index;
		}
	}

	// Look for other attributes
	const pointData = polyData.getPointData();

	// Normals
	const normalAttributeId = decoder.GetAttributeId(
		dracoGeometry,
		decoderModule.NORMAL,
	);

	if (normalAttributeId !== -1) {
		const normalArray = getDracoAttributeAsFloat32Array(
			dracoGeometry,
			decoderModule.NORMAL,
			decoderModule,
		);

		const normals = vtkDataArray.newInstance({
			numberOfComponents: 3,
			values: normalArray,
			name: 'Normals',
		});

		pointData.setNormals(normals);
	}

	// Texture coordinates
	const texCoordAttributeId = decoder.GetAttributeId(
		dracoGeometry,
		decoderModule.TEX_COORD,
	);

	if (texCoordAttributeId !== -1) {
		const texCoordArray = getDracoAttributeAsFloat32Array(
			dracoGeometry,
			texCoordAttributeId,
			decoderModule,
		);

		const texCoords = vtkDataArray.newInstance({
			numberOfComponents: 2,
			values: texCoordArray,
			name: 'TCoords',
		});

		pointData.setTCoords(texCoords);
	}

	// Scalars
	const colorAttributeId = decoder.GetAttributeId(
		dracoGeometry,
		decoderModule.COLOR,
	);

	if (colorAttributeId !== -1) {
		const colorArray = getDracoAttributeAsFloat32Array(
			dracoGeometry,
			colorAttributeId,
			decoderModule,
		);

		// Only get Red channel

		const intensity = new Float32Array(colorArray.length / 3);

		let colorIndex = 0;

		for (let index = 0; index < intensity.length; index++) {
			intensity[index] = colorArray[colorIndex];

			colorIndex += 3;
		}

		const scalars = vtkDataArray.newInstance({
			numberOfComponents: 1,
			values: intensity,
			name: 'Scalars',
		});

		pointData.setScalars(scalars);
	}

	decoderModule.destroy(decoder);
	return polyData;
}

function vtkDracoReader(publicAPI, model) {
	// Set our className
	model.classHierarchy.push('vtkDracoReader');

	// Create default dataAccessHelper if not available
	if (!model.dataAccessHelper) {
		model.dataAccessHelper = DataAccessHelper.get('http');
	}

	// Internal method to fetch Array
	async function fetchData(url, option = {}) {
		const {compression, progressCallback} = model;
		if (option.binary) {
			const firebaseAuthIdToken = await firebase.auth.getIdToken();
			return model.dataAccessHelper.fetchBinary(url, {
				compression,
				headers: {
					firebaseAuthIdToken,
				},
				progressCallback,
			});
		}

		return model.dataAccessHelper.fetchText(publicAPI, url, {
			compression,
			progressCallback,
		});
	}

	// Set DataSet url
	publicAPI.setUrl = (url, option = {}) => {
		model.url = url;

		// Remove the file in the URL
		const path = url.split('/');
		path.pop();
		model.baseURL = path.join('/');

		model.compression = option.compression;

		// Fetch metadata
		return publicAPI.loadData({
			progressCallback: option.progressCallback,
			binary: option.binary === undefined ? true : Boolean(option.binary),
		});
	};

	// Fetch the actual data arrays
	publicAPI.loadData = async (option = {}) => {
		const promise = fetchData(model.url, option);
		promise.then(publicAPI.parse).catch((error) => {
			vtkErrorMacro(error.message);
		});

		return promise;
	};

	publicAPI.parse = (content) => {
		publicAPI.parseAsArrayBuffer(content);
	};

	publicAPI.parseAsArrayBuffer = (content) => {
		if (!content) {
			return;
		}

		if (content === model.parseData) {
			return;
		}

		publicAPI.modified();

		model.parseData = content;
		const dracoGeometry = decodeBuffer(content);
		const polyData = getPolyDataFromDracoGeometry(dracoGeometry);
		decoderModule.destroy(dracoGeometry);
		model.output[0] = polyData;
	};

	publicAPI.requestData = () => {
		publicAPI.parse(model.parseData);
	};
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const defaultValues = {
	// BaseURL: null,
	// dataAccessHelper: null,
	// url: null,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
	Object.assign(model, defaultValues, initialValues);

	// Build VTK API
	macro.obj(publicAPI, model);
	macro.get(publicAPI, model, ['url', 'baseURL']);
	macro.setGet(publicAPI, model, ['dataAccessHelper']);
	macro.algo(publicAPI, model, 0, 1);

	// VtkDracoReader methods
	vtkDracoReader(publicAPI, model);

	/* eslint-disable unicorn/no-null */

	// To support destructuring
	if (!model.compression) {
		model.compression = null;
	}

	if (!model.progressCallback) {
		model.progressCallback = null;
	}

	/* eslint-enable unicorn/no-null */
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkDracoReader');

// ----------------------------------------------------------------------------

export default {
	extend,
	newInstance,
	setDracoDecoder,
	setWasmBinary,
	getDracoDecoder,
};
