import {
	Button,
	type ButtonProps,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	LinearProgress,
	Modal,
	ModalDialog,
	Stack,
	Typography,
} from '@mui/joy';
import delay from 'delay';
import {FileText, RulerIcon} from 'lucide-react';
import React, {type RefObject, useEffect, useState} from 'react';
import {useImmer} from 'use-immer';

import {Alert, ContactVent, Loading, StatusIcon} from '@/components';
import {useCustomDefaultsState, useFirebaseAuthState} from '@/hooks';
import {
	DensityIcon,
	DigitalTwinIcon,
	ResectionPlanesIcon,
	VisibilityIcon,
} from '@/icons';
import {api, isDefined} from '@/library';
import {Scan, type ScanSegmentation, type ScanState} from '@/library/models';
import {useGlobalState} from '@/state';
import {useViewportsStore} from '@/state/viewports';

import {
	Annotations,
	CustomDefaults,
	DensityMap,
	DigitalTwins,
	ResectionPlanes,
	SurgicalPlan,
	Visibility,
} from './components';
import {makeConvertAndUploadPointClouds} from './library/handle-upload-pointclouds';

type ErrorState = {
	message?: string;
	modalOpen?: boolean;
	recoverable?: boolean;
};

type WarningModalState = {
	buttons?: {
		primary?: ButtonProps;
		secondary?: ButtonProps;
	};
	message?: string;
};

type UploadProgress = Record<string, number>;

type Props = {
	readonly scan: Scan;
	readonly surgicalPlanRef: RefObject<HTMLDivElement>;
	readonly unrecoverableError?: Error;
};

// eslint-disable-next-line complexity
export default function SidePanel({
	scan,
	surgicalPlanRef,
	unrecoverableError,
}: Props) {
	const customDefaultsState = useCustomDefaultsState();
	const {
		annotations: {
			annotations,
			areAnnotationsDirty,
			notes,
			setAreAnnotationsDirty,
		},
		densityMap: {arePointCloudsDirty, state: densityMapState},
		digitalTwins: {areDigitalTwinsDirty, digitalTwins, setAreDigitalTwinsDirty},
		meshes,
		resectionPlanes: {
			adjustments: resectionPlanesAdjustments,
			dirtyProperty: resectionPlanesDirtyProperty,
			setDirtyProperty: setResectionPlanesDirtyProperty,
		},
		scan: {landmarks},
		tools: {active: activeTool},
		viewports: {
			axialViewport,
			coronalViewport,
			sagittalViewport,
			volumeViewport,
		},
	} = useGlobalState();

	const [firebaseAuthUser, isFirebaseAuthUserLoading] = useFirebaseAuthState();

	const [error, updateError] = useImmer<ErrorState>({});

	const {bounds} = useViewportsStore();

	function clearError() {
		updateError({});
	}

	function closeErrorModal() {
		updateError((draft) => {
			draft.modalOpen = false;
		});
	}

	const [warningModal, updateWarningModal] = useImmer<WarningModalState>({});

	function clearWarningModal() {
		updateWarningModal({});
	}

	const [uploadProgress, setUploadProgress] = useState<UploadProgress>({});

	// Local copy of scan state that we can update to optimistically render the
	// next state, but that is also synchronized with the scan's actual state
	// inspired by remix: https://remix.run/docs/en/main/guides/optimistic-ui
	const [scanState, setScanState] = useState<ScanState>(scan.state);
	useEffect(() => {
		setScanState(scan.state);
	}, [scan.state]);

	// Local copy of segmentation state for optimistic rendering, like `scanState`
	const [segmentation, setSegmentation] = useState<
		ScanSegmentation | undefined
	>(scan.segmentation);
	useEffect(() => {
		setSegmentation(scan.segmentation);
	}, [scan.segmentation]);

	useEffect(() => {
		switch (scanState) {
			case 'segmentKneeFailed': {
				updateError({
					message: 'Knee segmentation failed.',
					modalOpen: true,
				});

				break;
			}

			case 'segmentAnkleHipFailed': {
				updateError({
					message:
						'Ankle / hip segmentation failed. Edit point clouds to retry segmentation.',
					modalOpen: true,
				});

				break;
			}

			case 'landmarkFailed': {
				updateError({
					// TODO: add text to message explaining how to retry
					message: 'Landmarking failed.',
					modalOpen: true,
				});

				break;
			}

			default: {
				if (unrecoverableError) {
					updateError({
						message: 'Error loading files - try refreshing the page.',
						modalOpen: true,
						recoverable: false,
					});
				} else {
					clearError();
				}
			}
		}
	}, [scanState, unrecoverableError]);

	useEffect(() => {
		if (segmentation?.state === 'savingOutputsFailed') {
			updateError({
				message: 'Failed to save changes. Please try again',
				modalOpen: true,
			});
		}
	}, [segmentation?.state]);

	async function handleClickApprove() {
		updateWarningModal({
			buttons: {
				primary: {
					children: 'Approve',
					color: 'success',
					async onClick() {
						setScanState('landmarkApproved');

						try {
							await scan.update({state: 'landmarkApproved'});
						} catch (error) {
							setScanState('landmarkSucceeded');
							// TODO: render error
							console.error(error);
						}

						clearWarningModal();
					},
				},
				secondary: {
					children: 'Go back',
					onClick: clearWarningModal,
				},
			},
			message:
				'Please make sure you have reviewed the resection plane depth and angle before clicking the approve button. Once approved, editing is no longer available.',
		});
	}

	async function handleClickLandmark() {
		updateWarningModal({
			buttons: {
				primary: {
					children: 'Start landmarking',
					async onClick() {
						setScanState('landmarkInProgress');

						try {
							await api.tasks.create({
								scanId: scan.id,
								type: 'landmark',
							});
						} catch (error) {
							setScanState('landmarkFailed');
							console.error(error);
							// TODO: render error
						}

						clearWarningModal();
					},
				},
				secondary: {
					children: 'Go back',
					onClick: clearWarningModal,
				},
			},
			message:
				'Please make sure you have reviewed the generated meshes to ensure that each bone is outlined and labeled correctly before proceeding to the landmarking stage.',
		});
	}

	async function handleClickSegment() {
		setScanState('segmentAnkleHipInProgress');

		try {
			await api.tasks.create({
				scanId: scan.id,
				type: 'segmentAnkleHip',
			});
		} catch (error) {
			setScanState('segmentAnkleHipFailed');
			console.error(error);
			// TODO: render error
		}
	}

	async function handleSavingOutputs() {
		if (arePointCloudsDirty) {
			setSegmentation({
				state: 'savingOutputs',
				version: (scan.segmentation?.version ?? 0) + 1,
			});
		}

		await api.tasks.create({
			scanId: scan.id,
			type: 'saveOutput',
		});
	}

	const areResectionPlanesDirty = resectionPlanesDirtyProperty !== undefined;
	const shouldPreventEditing =
		Scan.isSegmentationUpdating(segmentation) ||
		scan.hasReachedMilestone('approved');

	async function handleClickSave() {
		// TODO: Explain why this is here:
		await delay(200);

		let message = '';
		if (
			areAnnotationsDirty ||
			arePointCloudsDirty ||
			resectionPlanesDirtyProperty === 'rotation' ||
			areDigitalTwinsDirty
		) {
			if (areAnnotationsDirty) {
				message =
					'Would you like to save your annotations? Annotations must be completed in a single session, but all screenshots, metadata and notes will be saved and accessible in the Surgical Plan.';
			} else if (areDigitalTwinsDirty) {
				message = 'Would you like to save your changes to the Digital Twins?';
			} else if (arePointCloudsDirty) {
				message =
					'Lasso tool is intended for removal of small defects or osteophytes, not large point cloud modifications. If edits made with the lasso tool are saved, landmarks and corresponding resection planes could be affected. Vent cannot guarantee the accurate placement of planes if landmarks are significantly altered. Please be sure you would like to proceed with these changes.';
			} else if (resectionPlanesDirtyProperty === 'rotation') {
				message =
					"Changes to geometries and planes is considered off-label from the intended use of the Vent Hermes system. Vent can't guarantee the proper function of the systems based on your changes. Are you sure you want to proceed?";
			}

			updateWarningModal({
				buttons: {
					primary: {
						children: 'Continue',
						async onClick() {
							clearWarningModal();

							if (areAnnotationsDirty) {
								await saveAnnotations();
							} else if (areDigitalTwinsDirty) {
								await saveDigitalTwins();
							} else if (arePointCloudsDirty) {
								await savePointCloudAdjustments();
							} else if (areResectionPlanesDirty) {
								await saveResectionPlanes();
							}
						},
					},
					secondary: {
						children: 'Cancel',
						color: 'danger',
						onClick: clearWarningModal,
					},
				},
				message,
			});
		} else if (areResectionPlanesDirty) {
			await saveResectionPlanes();
		}
	}

	async function savePointCloudAdjustments() {
		setSegmentation({
			state: 'uploadingPointClouds',
			version: scan.segmentation?.version ?? 0,
		});

		const convertAndUploadPointClouds = makeConvertAndUploadPointClouds({
			// TODO: test this
			handleError(message) {
				updateError({
					message,
					modalOpen: true,
				});
			},
			handleUploadProgress({fileName, currentProgress}) {
				setUploadProgress((previousProgress: UploadProgress) => ({
					...previousProgress,
					[fileName]: currentProgress,
				}));
			},
			onSavingOutputs: handleSavingOutputs,
			scanId: scan.id,
			scanSegmentationVersion: scan.segmentation?.version ?? 0,
			userId: firebaseAuthUser?.uid,
		});

		await convertAndUploadPointClouds();
	}

	async function saveAnnotations() {
		await scan.saveAnnotations({annotations, notes});

		setAreAnnotationsDirty(false);
	}

	async function saveDigitalTwins() {
		await scan.saveDigitalTwins(digitalTwins);

		setAreDigitalTwinsDirty(false);
	}

	async function saveResectionPlanes() {
		await scan.addResectionPlaneAdjustment(resectionPlanesAdjustments);

		setResectionPlanesDirtyProperty(undefined);
	}

	let pendingStateMessage: string | undefined;
	if (Scan.isSegmentationUpdating(segmentation)) {
		pendingStateMessage = 'Saving changes';
	} else if (Scan.isSegmenting(scanState)) {
		pendingStateMessage = 'Segmenting scan';
	} else if (Scan.isLandmarking(scanState)) {
		pendingStateMessage = 'Landmarking scan';
	}

	if (isFirebaseAuthUserLoading) return;

	return (
		<>
			{/* error modal */}
			<Modal open={error.modalOpen ?? false}>
				<ModalDialog>
					<DialogTitle>
						<StatusIcon color="danger" />
						Error
					</DialogTitle>

					<Divider />

					<DialogContent>
						<Typography>
							{error.message} <ContactVent capitalize component="link" /> for
							more information.
						</Typography>
					</DialogContent>

					<DialogActions>
						<Button
							onClick={() => {
								if (error.recoverable === false) {
									window.location.reload();
									return;
								}

								closeErrorModal();
							}}
						>
							{error.recoverable === false ? 'Reload' : 'OK'}
						</Button>
					</DialogActions>
				</ModalDialog>
			</Modal>

			{/* warning modal */}
			<Modal open={isDefined(warningModal.message)}>
				<ModalDialog>
					<DialogTitle>
						<StatusIcon color="warning" />
						Warning
					</DialogTitle>

					<Divider />

					<DialogContent>
						<Typography>{warningModal.message}</Typography>
					</DialogContent>

					<DialogActions>
						{warningModal.buttons?.primary && (
							<Button {...warningModal.buttons.primary} />
						)}
						{warningModal.buttons?.secondary && (
							<Button {...warningModal.buttons.secondary} variant="outlined" />
						)}
					</DialogActions>
				</ModalDialog>
			</Modal>

			{/* side panel body */}
			<Stack data-testid="side-panel" spacing={4}>
				<CustomDefaults />

				{customDefaultsState === 'inactive' && (
					<>
						{error.message && (
							<Alert showIcon color="danger">
								<Typography level="body-sm">
									{error.message}{' '}
									<ContactVent capitalize color="danger" component="link" /> for
									more information.
								</Typography>
							</Alert>
						)}

						{/* pending state */}
						{pendingStateMessage && (
							<Loading label={pendingStateMessage} size="sm" />
						)}

						{/* point cloud upload progress */}
						{segmentation?.state === 'uploadingPointClouds' &&
							Object.entries(uploadProgress).map(([fileName, progress]) => (
								<div key={fileName}>
									<Typography>
										Uploading {fileName}: {Math.ceil(progress)}%
									</Typography>
									<LinearProgress determinate value={Math.ceil(progress)} />
								</div>
							))}

						{/* 'ready to segment' actions */}
						{Scan.canBeSegmented(scanState) && (
							<>
								<Divider />

								{/* eslint-disable-next-line react/jsx-no-bind --
								 * This is OK.
								 * https://react.dev/reference/react/useCallback#should-you-add-usecallback-everywhere
								 */}
								<Button onClick={handleClickSegment}>Start segmentation</Button>

								<Typography>
									If something looks wrong, <ContactVent component="link" />.
								</Typography>
							</>
						)}

						{/* segmented actions */}
						{scanState === 'segmentAnkleHipSucceeded' &&
							!shouldPreventEditing && (
								<>
									<Alert showIcon color="success">
										Segmentation succeeded
									</Alert>

									<Divider />

									{/* eslint-disable-next-line react/jsx-no-bind --
									 * This is OK.
									 * https://react.dev/reference/react/useCallback#should-you-add-usecallback-everywhere
									 */}
									<Button onClick={handleClickLandmark}>
										Start Landmarking
									</Button>

									<Typography>
										If something looks wrong, <ContactVent component="link" />.
									</Typography>
								</>
							)}

						{/* landmarked actions */}
						{scanState === 'landmarkSucceeded' && !shouldPreventEditing && (
							<>
								<Alert showIcon color="success">
									Landmarking succeeded
								</Alert>

								{(arePointCloudsDirty || areResectionPlanesDirty) && (
									<Alert showIcon color="info">
										Save or revert your{' '}
										{arePointCloudsDirty ? 'point cloud' : 'resection plane'}{' '}
										changes to approve the plan.
									</Alert>
								)}

								<Button
									disabled={arePointCloudsDirty || areResectionPlanesDirty}
									/* eslint-disable-next-line react/jsx-no-bind --
									 * This is OK.
									 * https://react.dev/reference/react/useCallback#should-you-add-usecallback-everywhere
									 */
									onClick={handleClickApprove}
								>
									Approve Plan
								</Button>
							</>
						)}

						{/* final state */}
						{scanState === 'landmarkApproved' && (
							<Alert showIcon color="success">
								Plan approved
							</Alert>
						)}
					</>
				)}

				{/* active side panel tool */}
				{activeTool !== undefined && (
					<>
						<Divider />

						<Stack spacing={4}>
							{/* annotations */}
							{activeTool === 'annotations' && (
								<>
									<Typography
										component="h2"
										level="title-lg"
										startDecorator={<RulerIcon />}
									>
										Annotations
									</Typography>

									<Annotations
										scan={scan}
										viewports={{
											axial: axialViewport,
											coronal: coronalViewport,
											sagittal: sagittalViewport,
											volume: volumeViewport,
										}}
										/* eslint-disable-next-line react/jsx-no-bind --
										 * This is OK.
										 * https://react.dev/reference/react/useCallback#should-you-add-usecallback-everywhere
										 */
										onSave={handleClickSave}
									/>
								</>
							)}
							{/* visibility */}
							{activeTool === 'visibility' && (
								<>
									<Typography
										component="h2"
										level="title-lg"
										startDecorator={<VisibilityIcon />}
									>
										Visibility
									</Typography>

									<Visibility scan={scan} />
								</>
							)}

							{/* resection planes */}
							{activeTool === 'resectionPlanes' && (
								<>
									<Typography
										component="h2"
										level="title-lg"
										startDecorator={<ResectionPlanesIcon />}
									>
										Resection Planes
									</Typography>

									<ResectionPlanes
										arePointCloudsDirty={arePointCloudsDirty}
										areResectionPlanesDirty={areResectionPlanesDirty}
										isLoading={!meshes.loaded || densityMapState === 'loading'}
										isSegmentationUpdating={Scan.isSegmentationUpdating(
											segmentation,
										)}
										scan={scan}
										/* eslint-disable-next-line react/jsx-no-bind --
										 * This is OK.
										 * https://react.dev/reference/react/useCallback#should-you-add-usecallback-everywhere
										 */
										onSaveChanges={handleClickSave}
									/>
								</>
							)}

							{/* density map */}
							{activeTool === 'densityMap' && (
								<>
									<Typography
										component="h2"
										level="title-lg"
										startDecorator={<DensityIcon />}
									>
										Density Map
									</Typography>

									<DensityMap />
								</>
							)}

							{activeTool === 'digitalTwins' && (
								<>
									<Typography
										component="h2"
										level="title-lg"
										startDecorator={<DigitalTwinIcon />}
									>
										Digital Twins
									</Typography>

									{landmarks && bounds ? (
										<DigitalTwins
											areDigitalTwinsDirty={areDigitalTwinsDirty}
											scan={scan}
											onEditDigitalTwins={() => {
												setAreDigitalTwinsDirty(true);
											}}
											/* eslint-disable-next-line react/jsx-no-bind --
											 * This is OK.
											 * https://react.dev/reference/react/useCallback#should-you-add-usecallback-everywhere
											 */
											onSave={handleClickSave}
										/>
									) : (
										<Loading label="Loading" size="sm" />
									)}
								</>
							)}

							{activeTool === 'surgicalPlan' && (
								<>
									<Typography
										component="h2"
										level="title-lg"
										startDecorator={<FileText />}
									>
										Surgical Plan
									</Typography>

									<SurgicalPlan componentRef={surgicalPlanRef} />
								</>
							)}
						</Stack>
					</>
				)}
			</Stack>
		</>
	);
}
