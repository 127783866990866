import {useEffect, useState} from 'react';

import {cornerstone} from '@/library';
import {useGlobalState, useViewportsStore} from '@/state';

import addLandmarksToCornerstone3dToolState from '../utils/add-landmarks-to-cornerstone-3d-tool-state';
import {createLandmarks} from '../utils/landmark-reader';
import {type Scan} from '../library/models';

const useLoadLandmarks = ({
	arePointCloudsLoaded,
	scan,
}: {
	arePointCloudsLoaded: boolean;
	scan: Scan;
}) => {
	const {meshes} = useGlobalState();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const {isVtkInitialized} = useViewportsStore.getState();

	useEffect(() => {
		if (
			!isLoaded &&
			isVtkInitialized &&
			meshes.loaded &&
			arePointCloudsLoaded &&
			scan.hasReachedMilestone('landmarked') &&
			scan.landmarks
		) {
			if (!cornerstone.areElementsDefined()) {
				throw new Error('Invalid cornerstone elements');
			}

			const primaryLandmarks = scan.landmarks.primary;

			createLandmarks(primaryLandmarks);

			addLandmarksToCornerstone3dToolState(primaryLandmarks);
			setIsLoaded(true);
		}
	}, [
		isLoaded,
		setIsLoaded,
		meshes.loaded,
		arePointCloudsLoaded,
		isVtkInitialized,
		scan.state,
	]);

	return {isLoaded};
};

export default useLoadLandmarks;
