import {
	PickResult,
	PickType,
	HandleType,
	InteractionType,
} from '@/library/gumball';
import {
	createGumball,
	createOutline,
	removeGumball,
	setActorOpacity,
	setGumballHandlesOpacity,
} from '@/library/vtk/gumball';
import {useDigitalTwinsStore} from '@/state/digital-twins';
import {useInteractionStore} from '@/state/interaction';
import {getViewports} from '@/state/viewports';

import {pickActor} from './pick';

const resetPickable = () => {
	const {digitalTwins} = useDigitalTwinsStore.getState();

	for (const actorData of digitalTwins) {
		actorData.actor.setPickable(true);
	}
};

const handlePickActor = (pickResult: PickResult) => {
	const {selectDigitalTwin, digitalTwins} = useDigitalTwinsStore.getState();
	const selectedActorData = digitalTwins.find(
		(actorData) => actorData.id === pickResult.actorData?.id,
	);

	if (pickResult.actorData && selectedActorData) {
		resetPickable();
		selectedActorData.actor.setPickable(false);
		selectDigitalTwin(selectedActorData.id);
		createGumball(pickResult.actorData);
	}
};

const handlePickGumball = (pickResult: PickResult) => {
	const {
		volume: {renderer},
	} = getViewports();
	const {digitalTwins, outlineActor, selectedDigitalTwinId, setOutlineActor} =
		useDigitalTwinsStore.getState();
	const {gumball, setPickedAxis, setPickedGumballHandle} =
		useInteractionStore.getState();

	const selectedActorData = digitalTwins.find(
		(actorData) => actorData.id === selectedDigitalTwinId,
	);

	if (gumball && selectedActorData && pickResult.pickedActor?.actor) {
		setActorOpacity(selectedActorData.actor, 0.5);
		setGumballHandlesOpacity(gumball, 0.3, pickResult.pickedActor.actor);

		if (!outlineActor) {
			const {actor: newOutlineActor} = createOutline(selectedActorData);
			renderer?.addActor(newOutlineActor);
			setOutlineActor(newOutlineActor);
		}

		setPickedAxis(pickResult.axis);
		setPickedGumballHandle(pickResult.pickedActor.actor);
	}
};

const setInteractionType = (
	pickResult: PickResult,
	setCurrentInteractionType: (type: InteractionType) => void,
) => {
	switch (pickResult.handle) {
		case HandleType.Resize: {
			setCurrentInteractionType('resize');

			break;
		}

		case HandleType.Translation: {
			setCurrentInteractionType('translate');

			break;
		}

		case HandleType.Rotation: {
			setCurrentInteractionType('rotate');

			break;
		}

		default: {
			setCurrentInteractionType(undefined);
		}
	}
};

export const handlePicking = (position: {x: number; y: number}) => {
	const {digitalTwins, selectedDigitalTwinId} = useDigitalTwinsStore.getState();
	const {gumball, setCurrentInteractionType, setIsDragging, setLastPosition} =
		useInteractionStore.getState();
	const {
		volume: {renderWindow},
	} = getViewports();
	const pickResult = pickActor(position);
	let selectedActorData = digitalTwins.find(
		(actorData) => actorData.id === pickResult.actorData?.id,
	);

	if (pickResult.picked) {
		setIsDragging(true);
		setLastPosition(position);

		switch (pickResult.type) {
			case PickType.Actor: {
				if (pickResult.actorData) {
					handlePickActor(pickResult);
				}

				if (pickResult.actorData?.id !== selectedActorData?.id) {
					resetPickable();
				}

				break;
			}

			case PickType.Gumball: {
				if (gumball) {
					handlePickGumball(pickResult);
					setInteractionType(
						pickResult,
						setCurrentInteractionType as (type: InteractionType) => void,
					);
				}

				break;
			}

			default: {
				throw new Error('Invalid pick type');
			}
		}
	} else {
		selectedActorData = digitalTwins.find(
			(actorData) => actorData.id === selectedDigitalTwinId,
		);
		if (selectedActorData) {
			setActorOpacity(selectedActorData?.actor, selectedActorData?.opacity);
		}

		if (gumball) {
			removeGumball();
		}

		setCurrentInteractionType(undefined);
		resetPickable();
	}

	renderWindow?.render();
};
